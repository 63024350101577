.manual_grouping_card_container{
    border: 1px solid #cdced9;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}
.mg_title_wp{
display: flex;
align-items: center;
gap: 5px;
}

.manual_grouping_header_section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dtl_wraper_container{
    display: flex;
    align-items: center;
    gap:8px;
    margin-top: 5px;
}

.mg_textbox_container{
    display: flex;
    align-items: center;
    gap: 5px;
}
.cp_tag{
    padding: 2px 5px;
    border-radius: 5px;
}