.uploaded-docs-main-container{
    border: 1px solid #CDCED9;
    background-color: #ffffff;
    border-radius: 5px;
    width: 450px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap:10px
}

.uploaded-docs-sub-container{

    display: flex;

    gap: 6px;

    align-items: center;

}
.uploaded-docs-details{
    display: flex;
    flex-direction: column;
    gap:10px
}
.uploaded-name-status{
    display: flex;
    gap: 10px;
}
.uploaded-docs-time{
    // display: 'flex';
    // flex-direction: column;
    // gap: 12px;
    flex: 1;
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
}
.docs-list-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}
.docs-list-header{
    display: flex;
    justify-content: space-between;
}


//legal-footer-timer component 
.timer-button{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 110px;
    border: 1px solid #CDCED9;
    box-shadow: 0px -1px 7px 0px #00000024 inset;
border-radius: 5px;
cursor: pointer;
}
