.upload-color-change::before {
  color: #ffffff !important;
}
.mx-icon-arrow_drop_down::before {
  font-size: 20px !important;
}
.noti .mx-icon-info:before {
  font-size: 22px;
  color: #516beb !important;
}
.noti-isactive .mx-icon-info {
  font-size: 22px;
}

.noti .mx-icon-calendar:before {
  font-size: 19px;
  color: #516beb !important;
}

.noti-isactive .mx-icon-calendar {
  font-size: 19px;
  // color: #516beb;
}

.mx-icon-Group-16860 {
  font-size: 15px;
}
.mx-icon-clear {
  font-size: 25px;
}
.mx-icon-unview {
  font-size: 15px;
}
.mx-icon-view {
  font-size: 15px;
}
