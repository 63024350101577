.mx-qb-fields,
.mx-qb-operators,
.mx-qb-value input,
.mx-qb-value:empty {
  padding: 5px;
  border: 1px solid var(--border);
  border-radius: 5px;
  color: var(--text-color);
  width: 150px;
  font-size: 13px;
}

.mx-qb-value input {
  width: 100px;
}

.mx-qb-value select {
  padding: 5px;
  border: 1px solid var(--border);
  border-radius: 5px;
  color: var(--text-color);
  width: 150px;
  font-size: 13px;
}

.mx-qb-value {
  display: flex;
  gap: 5px;
}

.mx-qb-operators {
  width: 75px;
}

.mx-qb-rule {
  display: flex;
  gap: 5px;
  padding: 5px;
}

.mx-qb-remove-rule {
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 13px;
  // border: 1px solid var(--border);
}

.mx-qb-add-rule {
  padding: 5px 10px;
  background: var(--primary);
  color: var(--white);
  border-radius: 5px;
}

.mx-qb-rule-group {
  display: flex;
  flex-direction: column-reverse;
  border: none !important;
}

.mx-qb-rule-group_role {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #cdced9 !important;
  background-color: #fff;
}
.mx-rule-rule-group-body {
  background-color: #fff;
  // border: 1px solid #cdced9 !important;
}
.mx-rule-query-builder-group {
  background-color: #fff;
  border: 1px solid #cdced9 !important;
}
.mx-rule-header-group {
  background-color: #fff;
  // border: 1px solid #cdced9 !important;
}
.ruleGroup {
  background-color: #fff;
  border: 1px solid #cdced9;
}
