.report_filter {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #c7c7c7;
    padding: 6px 10px;
    border-radius: 5px;
}

.filter_active {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #516beb;
    padding: 6px 10px;
    border-radius: 5px;
}

.filter_active div {
    color: #516beb;
}