.customize-table-container {
  padding: 15px;
}

.customize-table-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.typo-tab-head {
  color: #787878;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.typo-tab-data {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

.customize-table-data {
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.customize-table-data table {
  width: 100% !important;
}

.customize-table-data table thead th {
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-left: 10px;
}

.customize-table-data table tbody td:nth-child(2) {
  border-right: none;
}

.customize-table-data table tbody td {
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-left: 10px;
}

.customize-table-last-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.customize-label-input-box {
  width: 239px;
  padding: 5px 5px 5px 0;
}

.customize-table-container-field-data {
  padding-right: 95px;
}

.cus-lab-desk-table table {
  border: 1px solid #cdced9;
}

.cus-lab-desk-table table thead {
  background-color: #f9f9f9;
}

.cus-lab-desk-table table thead th {
  text-align: left;
}

.cus-lab-desk-table table tbody td {
  text-align: left;
  padding: 10px 0px;
  // padding-left: 5px
}

.cus-lab-mobile-view-body-container {
  padding-right: 250px;
}

.cus-lab-mobile-view-body {
  border: 1px solid #cdced9;
  padding: 10px;
}

.custom-field-row td {
  padding-right: 10px;
  padding-top: 10px;
}

.custom-table {
  border-top: 1px solid #cdced9;
  border-left: 1px solid #cdced9;
}

.custom-table table {
  width: 100% !important;
}

.custom-table table thead th {
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  // padding-left: 10px;
  padding: 10px;
}

.custom-table table tbody td {
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  padding-left: 10px;
}