.consumer-contact-container {
  height: 237px;
  background-color: #f9fbff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}
.review-consumer-header {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.consumer-contact-details {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  justify-content: space-between;
  gap: 10px;
}
.consumer-invoices-list {
  display: flex;
  gap: 10px;
}

.consumer-contact-separator {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.review-contact-primary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  position: relative;
  bottom: 45px;
}
.review-consumer-container {
  background-color: #ffffff;
  border: 1px solid #cdced9;
  // width: 660px;
  height: 217px;
  border-radius: 5px;
  // display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.review-consumer-invoices-dsc {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  justify-content: flex-end;
  gap: 10px;
}
.review-primary-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  bottom: 90px;
}
.review-line-separtor {
  width: 662px;
  color: #eeeeee;
  border: 1px solid #eeeeee;
  align-self: center;
}
.review-consumer-contact-container {
  position: relative;
  bottom: 18px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
}
.review-consumer-suggest_line {
  width: 610px;
  color: #eeeeee;
  border: 1px solid #eeeeee;
  align-self: center;
}
.review-suggestion-button_div {
  display: flex;
  gap: 2px;
  padding-top: 2px;
  padding-left: 2px;
}

.invoice-eclipse-separtor {
  height: 3px;
  width: 3px;
  background: #adadad;
  border-radius: 50px;
  margin: 6px;
}
.group-selection {
  width: 295px;
  height: 27px;
  border: 1px solid $border;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.group-label_select {
  flex: 1 1 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//group-container
.group-selection-list-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 340px;
  padding-left: 10px;
  height: 28px;
  gap: 5px;
}

.group-selection-list-sub-container {
  width: 293px;
  padding: 6px;
  height: 240px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.group-list-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  // padding-left: 10px;

  // padding-top: 10px;
}

.edit-group-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.grouping-invoices {
  display: flex;
  align-items: flex-start;
  position: relative;
  bottom: 10px;
  padding-left: 23px;
}
.grouping-invoices-currency {
  display: flex;
  position: relative;
  bottom: 15px;
  justify-content: flex-end;
}
.grouping-invoices-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1.5px solid #333860;
}

.grouping-customer-display-name {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding-left: 14px;
}

.group-tile {
  display: flex;
  justify-content: space-around;
  padding: 0px 5px;
  gap: 10px;
  height: 40px;
}

.group-tile_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  width: 240px;
}

.group-tile_content_header {
  display: flex;
  justify-content: space-between;
}
.group-tile_content_info {
  // position: relative;
  // right: 31px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;

  &_text {
    flex: 1 1 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--currency {
      flex: 2 1 0px;
    }
  }
}

.group-tile_timeline {
  padding-top: 5px;
}
.group-tile_timeline_line {
  width: 1px;
  background-color: #333860;
  height: 26px;
  margin-top: 4px;
  margin-left: 2px;
}

.review-card-container {
  display: flex;
  flex-direction: column;
  width: 650px;
  padding: 0px 5px;
  padding: 10px;
  gap: 10px;
}

.review-sub-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  padding: 5px;
  gap: 10px;
}
.review-title {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.review-header_invoices {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review-contact-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.review-name-dsc {
  display: flex;
  align-items: center;
}
.review-customer_name {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review-mobile {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review-emai {
  display: flex;
  align-items: center;
}
.review-address {
  display: flex;
  align-items: center;
  gap: 5px;
}
.business-unit {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review-email {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review-local-time {
  flex: 1;
  text-align: end;
}
.review-group-suggestion_content {
  display: flex;
  gap: 5px;
  padding-left: 5px;
}
.review-group_buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex: 1 1;
}
.review-header-count {
  cursor: pointer;
  width: 37px;
  height: 27px;
  display: flex;
  gap: 4px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: #ffeeed;
}
.review-localTime {
  display: flex;
  gap:3px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.review-contact-container {
  display: flex;
  flex-direction: column;
  width: 650px;
  padding: 0px 5px;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
}
.customer-hierary-popper {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.customer-hierary-popper.initial-highlight {
  background-color: #F0F5FF; /* Initial background color */
  cursor: pointer; /* Change cursor as desired */
}

.customer-hierary-popper:hover {
  background-color: #F0F5FF;
  cursor: pointer; /* Change cursor as desired */
}
.truncate-label {
  width: 250px; /* Adjust width according to your layout */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}