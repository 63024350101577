.before{
    display: flex;
    height: 55px;
    background-color: #FAFAFA;
    align-items: center;
    border: 0.5px solid #CDCED9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.after{
    display: flex;
    height: 55px;
    background-color: #FAFAFA;
    align-items: center;
    border: 0.5px solid #CDCED9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // border-bottom-left-radius: 5px;
    // border-bottom-right-radius: 5px;
}