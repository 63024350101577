// StateDrawer.module.scss
.queryBuilder {
  background-color: #fff;
  border: 1px solid #cdced9;
}
.ruleGroup {
  background-color: #fff;
  border: 1px solid #cdced9 !important;
}
.ruleGroup-body {
  padding: 10px;
}

@import "react-querybuilder/dist/query-builder.scss";
