.debt-counts-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 85px;
  background-color: #ffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-top: 15px;
}

.debts-counts-details {
  display: flex;
  align-items: center;
  gap: 5px;
}
.debt-add-debt_button {
  cursor: pointer;
  background-color: #e4f0fc;
  border: 1px solid #e4f0fc;
  border-radius: 3px;
  width: 156px;
  height: 35px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #daecff;
  }
}
.debt-add-debt_button:hover {
  background-color: #b2d9ff;
}

.debt-legal-invoice-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 345px;
  padding: 10px 5px;
}

.debt-legal-invoice_check {
  gap: 4px;
  overflow-y: auto; /* Use overflow-y: auto for smooth scrolling */
  display: flex;
  flex-direction: column;
  max-height: 300px; /* Set initial max-height */
}

.debt-legal-invoice_check.has-overflow {
  /* Add this class with JS when content overflows */
  max-height: unset; /* Remove max-height to allow scrolling */
}
.debt-legal-invoice-line {
  width: 318px;
  border: 0.8px solid #eeeeeeee;
}
.debt-legal-invoices_buttons {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  padding-right: 5px;
}

//motion css
.legal-add-motion_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.motion-landing-container {
  padding-top: 18px;
  padding: 10px;
}
.legal-law_image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  height: 70vh;
}

.motion-selection-container {
  display: flex;
  flex-direction: column;
}
.motion-add_button {
  text-align: end;
}
.edit-motion_button {
  display: flex;
  gap: 3px;
}

.motion-container {
  display: flex;
  justify-content: space-between;
}

.co_groping_delete_popover {
  width: 250px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cdced9;
  border-radius: 5px;
}

.co_groping_delete_popover_footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 8px;
  margin-top: 10px;
}

.co_groping_delete_popover_body {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #cdced9;
  padding: 0px 0px 10px 0px;
  justify-content: flex-start;
  align-items: flex-start;
}

//   .co_remove_group{
//     margin-top: 7px;
//     position: absolute;
//     right: 1%;

//   }

//Audit legal Action

.audit-selection-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.audit-selection_content {
  display: flex;
  gap: 25px;
}

//Defendants Legal

.defans-legal-popover {
  // height: 100vh;
  max-height: 155px;
  gap: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.defand-legal-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 345px;
  padding: 10px;
}
.modifi_row {
  background-color: #fafafa !important;
  color: #adadad;
}
.default_row {
  background-color: #ffff;
}
.modifi_row p {
  color: #adadad;
}
// .motion-count-ripple {
//   text-align: center;
//   color: #ffffff;
//   border: 3px solid #dde3ff;
//   width: 23px;
//   border-radius: 9px;
//   background-color: #516beb;
// }

.header-legal-action-content {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 3px;
}
// $color: #dde3ff;
// .motion-count-ripple {
//   text-align: center;
//   color: #ffffff;
//   border: 3px solid #dde3ff;
//   width: 23px;
//   border-radius: 9px;
//   background-color: #516beb;
//   background: transparent;
//   animation: ripple 1.5s linear infinite;
//   transition: all 0.7s ease;
//   &:hover {
//     transform: scale(0.5);
//   }
//   &:focus {
//     outline: none;
//   }
// }

// @keyframes ripple {
//   0% {
//     box-shadow: 0 0 0 0rem rgba($color, 0.2), 0 0 0 1rem rgba($color, 0.2),
//       0 0 0 2rem rgba($color, 0.2), 0 0 0 5rem rgba($color, 0.2);
//   }
//   100% {
//     box-shadow: 0 0 0 1rem rgba($color, 0.2), 0 0 0 2rem rgba($color, 0.2),
//       0 0 0 5rem rgba($color, 0.2), 0 0 0 8rem rgba($color, 0);
//   }
// }
