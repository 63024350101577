.defendants-initial-container {
  height: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding: 5px;
}
.defendants-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.defendants-not-available {
  flex: 1;
  display: flex;
  gap: 15px;
  align-items: center;

  flex-direction: column;
  justify-content: center;
}

//card of defendants
.defendant-card-container {
  padding: 10px;
  height: Fixed (258px);
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid #cdced9;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
}

.defendant-card_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.defendant-expand {
  text-align: end;
  flex: 1;
  cursor: pointer;
}

.defendant-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

//documents Download

.document-legal-upload-container {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #cdced9;
  border-radius: 5px;
}
.document-legal-upload-sub-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.upload-document_div {
  border: 2px dotted #cdced9;
  border-radius: 5px;
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fbfcff;
}
.category-section-download {
  display: flex;
  gap: 5px;
}
.poper-category-docs .regular:hover {
  background-color: #f0f5ff;
  cursor: pointer;
}

.list-docs-card_category {
  background-color: #ffff;
  width: 250px;
  padding: 5px;
  height: 28px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-docs-invoices_select {
}
.downloaded-invoices-doc {
  display: flex;
  align-items: center;

  width: 225px;
  height: 40px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 5px;
}
