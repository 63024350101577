.db-status__section-card {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
  flex: 1;
  box-shadow: 0px 8px 24px 0px #959da520;
}

.db-status__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.db-sataus__card-container {
  display: flex;
  gap: 20px;
}

.db-status__icon-bg {
  background-color: #fdcac6;
  border-radius: 50px;
  padding: 25px;
}

.db-status__icon-bg-blue {
  background-color: #dde3ff;
  border-radius: 50px;
  padding: 25px;
}

// .I_symble{
//   border: 1px solid #CDCED9;
//   padding: 0px 6px;
//   text-align: center;
//   border-radius: 50px;
// }
.db-action__table {
  background-color: #ffffff;
  flex: 1;
  border-radius: 5px;
  border: 0.5px solid #cdced9;
  box-shadow: 7px 11px 10px rgba(149, 157, 165, 0.2);
}

// .db-action__table {
//   background-color: #ffffff;
//   flex: 1;
//   border-radius: 5px;
// }

.--changed {
  background-color: #ffffff;
  width: 750px;
  flex: 0.5;
  border-radius: 5px;
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
}

.InteractionCustomer {
  border-radius: 42px;
  width: 39px;
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.db-action__table-body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  height: 57px;
  border-bottom: 0.5px solid #e7e7e7;
}

.db-action__table-body-container {
  height: 360px;
  overflow: scroll;
}

.db-action__table-body-cell__name {
  flex: 2;
}

.db-action__table-body-cell {
  padding: 10px;
  flex: 1;
}

.db-action__table-body:hover {
  background-color: #eeeeee;
  box-shadow: 2.4px 2.4px 3.2px #00000015;
}

.db-action__table-head {
  height: 57px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #e7e7e7;
}

.db-action__head-border {
  width: 7px;
  height: 100%;
  background-color: #516beb;
  border-top-left-radius: 5px;
}

.--modifier_table {
  // width: 480px;
  flex: 1;
  background-color: #ffffff;
  border-radius: 5px;
  // height: 450px;
  overflow: hidden;
}

.dashboard__currency__wraper {
  display: flex;
  gap: 5px;
  flex-direction: row;
}

.search-clients {
  background-color: #ffffff;
  // min-width: 150px;
  // max-height: 300px;
  border-radius: 5px;
}

.search-client-box {
  margin-top: 5px;
}

.search-client-data {
  overflow: scroll;
  min-width: 150px;
  max-height: 250px;
}

.search-client-list {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-client-list:hover {
  background-color: #516beb25;
  color: #516beb;
  border-radius: 5px;
  cursor: pointer;
}

.search-client-list p {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.acc-rest_wraper {
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

.acc-rest_wraper p {
  padding: 0px 5px;
  font-size: 13px;
}

// .acc-rest_wraper p:hover {
//   background-color: #516beb25;
//   color: #516beb;
//   border-radius: 5px;
//   cursor: pointer;
// }

.section-wraper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0px 0px 0px 5px;
  position: relative;
  gap: 5px;
}

.acc-selected {
  position: absolute;
  background-color: #516beb;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  /* text-align: center; */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 31px;
  top: 2px;
  font-size: 9px;
  font-weight: 450;
}

.section-wraper p {
  cursor: pointer;
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  min-width: 100px;
  text-align: center;
}

.--slef-modifi {
  padding: 5px 10px 5px 10px;
}

.acc-rest-container {
  display: flex;
  // border: 1px solid #cdced9;
  box-shadow: 0px 2.4px 3.2px #00000015;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
}

.acc-rest-active {
  border-bottom: 3px solid #516beb;
}

.acc-rest-active p {
  color: #516beb;
  font-weight: 450;
}

.--layout_relative {
  position: relative;
}

.--acc_ablulate {
  position: absolute;
  top: 0;
  right: 35%;
}

.--modeal {
  position: absolute;
  top: 0;
  right: 50%;
}

.disable-field p {
  cursor: no-drop;
}

.--acc_ablulate_bs {
  position: absolute;
  top: 0;
  right: 46%;
}

//REVENUE-DASHBOARD -> Collection Relation Manager

.col-rel-manager-container {
  background-color: #fff;
  flex: 1;
  border: 1px solid #cdced9;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 8px 14px 0px rgba(149, 157, 165, 0.2);
}

.col-rel-manager-container-sec {
  flex: 1;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 79%;
  padding: 10px;
}

.col-rel-bar-sec {
  height: 80px;
  padding: 10px 9px;
  max-height: 300px;
}

.col-rel-footer-sec {
  display: flex;
  flex-direction: row;
  gap: 15px;
  background-color: #f8f8fb;
  border-color: 1px solid #cdced9;
  box-shadow: 0 8 24px 0px #959da520;
  padding: 5px;
  justify-content: center;
}

.col-rel-footer-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.col-rel-man-bar-container {
  padding: 0px 9px;
  margin-top: 10px;
  max-height: 230px;
  overflow-y: scroll;
}

.col-rel-footer-text-box {
  width: 17px;
  height: 17px;
  border-radius: 5px;
}

.col-rel-typo {
  font-size: 11px;
  font-weight: 400;
}