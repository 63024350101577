.cwf-container {
  background-color: #ffffff;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #cdced9;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.cwf-header {
  height: 100%;
  position: relative;
}

.content-cwf-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 15px;
}

.select {
  box-shadow: 0px 0px 0px 1px #516beb, 0px 0px 0px 2px #516beb25;
}

.wfm-attachment_btn {
  margin-top: 6px;

  & svg path {
    fill: #323232;
  }
}

.wfm-attachment-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.wfm-attached_files {
  display: flex;
  padding: 5px;
  background-color: #eeeeee;
  box-shadow: inset 0px 0px 2px 1px #33386025;
  border-radius: 5px;
  margin-top: 15px;
  justify-content: space-between;
  width: 115px;

  & p {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & :nth-child(3) {
    cursor: pointer;
  }
}