.settings__left-card {
  background-color: #fff;
  border: 1px solid #cdced9;
  border-radius: 5px 0px 0px 5px;
  box-shadow: 1px 2px 4px 0 #33386025;
  grid-column: 1/2;
  grid-row: 1/7;
  height: 100%;
  min-width: 350px;
  overflow: scroll;
}

.settings__right-tab {
  background: #fff;
  // border: 1px solid #cdced9;
  border-radius: 0px 5px 5px 0px;
  box-shadow: 1px 2px 4px 0 #33386025;
  grid-column: 2/5;
  grid-row: 1/7;
  height: 100%;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid #cdced9;
  border-top: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
}

.new_payment_plan {
  color: #516beb;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.acc__book__wraper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.acc__status__container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.acc__status__container p {
  width: 100px;
}

.obe__content__wraper {
  display: flex;
  margin-top: 20px;
  padding: 15px 30px;
  align-items: center;
  border: 1px solid #cdced9;
  border-radius: 5px;
  justify-content: space-between;
}

.obe__content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.--obe__h__modifi {
  height: 105px;
  cursor: pointer;
}

.--obe__h__modifi:hover {
  box-shadow: var(--elevation-1);
}

.obe__mail__btn__wraper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.obe__mail__btn__wraper p {
  color: #516beb;
  cursor: pointer;
}

.obe__mail__btn__wraper h6 {
  color: #516beb;
  background-color: #f2f5ff;
  padding: 0px 6px;
  border-radius: 5px;
  line-height: 20px;
}

.acc__book__info svg {
  width: 15px;
  height: 15px;
}

.acc__book__info svg path {
  fill: #333860;
}

.payment__dtl__container {
  display: flex;
  background: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 0px 5px 5px 0px;
  box-shadow: #33386025 1px 2px 4px 0px;
  flex: 1;
}

.payment__dtl__wraper_h {
  flex: 1;
}

.payment__dtl__wraper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.--payment__content {
  // overflow: scroll;
  padding: 15px 15px 10px 15px;
}

.payment__dtl__header {
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid #c7c7c7;
  justify-content: space-between;
  align-items: center;
}

.add__payment__conatiner {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add__payment__dtl__conatiner {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  // overflow: scroll;
  height: calc(100% - 70px);
}

.payment__back__btn {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

// .payment__back__btn:hover {
//   background-color: #adadad;
// }

.payment__back__btn svg {
  width: 15px;
  height: 15px;
}

.payment__back__btn svg path {
  fill: #516beb;
}

.payment__input__head__container {
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 10px;
}

.payment__input__label__content {
  display: flex;
  justify-content: space-between;
}

.payment__info__wraper {
  flex: 1;
}

.upload__file__field {
  height: 34px;
  width: 100%;
  border: 1px solid rgb(205, 206, 217);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
}

// iphone style start here
.payment__save__btn {
  height: 73px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #cdced9;
  padding: 0px 20px;
}

.payment__preview__side {
  width: 100%;
  height: 100%;
  background-image: url(../img/iphone.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.payment_dtl_box {
  display: flex;
  gap: 15px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  width: 55%;
  box-shadow: 0px 3px 4px 0px #516beb15;
  align-items: center;
}

.payment_preview_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.payment_dtl_img_wrapper {
  width: 20%;
  background-color: #506beb50;
  border-radius: 100px;
  padding: 5px 10px 10px 10px;
}

.payment_pre_pay_via {
  display: flex;
  gap: 15px;
  // width: 55%;
  margin: 5px 0px 5px 0px;
  // border-bottom: 1px solid #C7C7C7;
  align-items: center;
  // padding-bottom: 10px;
  position: relative;
  width: 100%;
  justify-content: start;
}

.arrow_size {
  font-size: 22px;
}

.payment_acc_dtl {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px 11px 15px;
  border-radius: 5px;
  border: 1px solid #cdced9;
  margin-bottom: 10px;
}

.payment_audit_btn {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 30px;
  justify-content: end;
}

.payment_audit_dtl {
  display: flex;
  justify-content: end;
  align-items: flex-start;
}

.payment__input__wraper {
  height: calc(100% - 85px);
  overflow: scroll;
  padding: 5px 15px;
}

.payment_ql_wraper {
  height: 300px;
  margin-top: 10px;
}

// .payment_ql_wraper .ql-container {
//   flex: 0;
// }

.recurring_fee_input_wraper {
  height: calc(100% - 85px);
  overflow: scroll;
  padding: 5px 15px;
}

.recurring_fee_dubel_fields {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.recurring_field_wraper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.recurring_type_off {
  display: flex;
  gap: 35px;
  align-items: center;
}

.recurring_fee_singel_field_wraper {
  display: flex;
  flex: 1;
  gap: 30px;
}

.tax_rate_maintenance_toggle_btn {
  padding: 0px 15px;
  cursor: pointer;
}

.tax_rate_main_toggle_wraper {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0%;
}

.smsgateway_header {
  color: #333860;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
  padding-bottom: 10px;
}

.smsgateway_para {
  color: #adadad;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.--ossmsgateway {
  padding-left: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.addsmsgateway {
  margin: 20px;
  border-radius: 5px;
  border: 1px solid #cdced9;
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px 10px;
  align-items: center;
  cursor: pointer;
}

.addsmsgateway_mess {
  color: #adadad;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 130.769% */
}

.addsmsgatewayicon {
  border-radius: 5px;
  background: #e2e9ff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smsgatewaydrawerheader {
  color: #333860;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
  padding-bottom: 35px;
}

.smsprovider {
  color: #333860;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
}

.addsmsdrawer {
  height: calc(100vh - 130px);
  overflow: auto;
}

.errors:hover {
  background: #ffdfdc;
  color: #fd372a;
  border-radius: 5px;
}

.phone_number {
  color: #787878;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.st_card_container {
  padding: 15px;
}

.st_card {
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.st_conten_contaner {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.st-icon-modifi {
  transform: rotate(90deg);
  padding: 10px 15px;
}

.st_btn_wraper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 25px;
}

.st_provider_wraper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.st_img_container {
  width: 60px;
  height: 60px;
}

.--lte_img {
  display: flex;
  align-items: center;
}

.st_img_container img {
  width: 100%;
}

//payment billing table

.text-typo {
  font-size: 14px;
  color: #333860;
  line-height: 18px;
  font-weight: 500;
}

.text-error-typo {
  color: #fd372a;
  text-align: left;
  padding-left: 13px;
  font-weight: 400;
}

.payment-prority-billing-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 0px 5px 5px 0px;
  box-shadow: rgba(51, 56, 96, 0.1450980392) 1px 2px 4px 0px;
  flex: 1 1;
}

.payment-billing-content {
  padding: 15px 15px 20px 25px;
}

.payment-priority-billing {
  margin-top: 30px;
  padding-right: 40px;
  flex: 1;
  overflow: auto;
  max-height: calc(100vh - 300px);
}

.payment-billing-header-section-line {
  border-bottom: 0.5px solid #c7c7c7;
}

.payment-priority-billing table {
  font-family: arial, sans-serif !important;
  border-collapse: collapse !important;
  width: 100% !important;
  border-collapse: collapse !important;
}

.payment-priority-billing table thead tr {
  background-color: #fafafa;
}

.payment-priority-billing table thead tr th {
  text-align: center;
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
}

.payment-priority-billing table tbody {
  align-items: center;
}

.payment-priority-billing table tbody tr td {
  text-align: center;
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
}

.payment-priority-billing table tbody tr td:nth-child(6) {
  text-align: left;
  padding-left: 20px;
}

.payment-priority-billing table thead tr th:nth-child(3) {
  width: 10%;
}

.payment-priority-billing table thead tr th:nth-child(5) {
  width: 10%;
}

.payment-priority-billing table thead tr th:nth-child(2) {
  width: 25%;
}

.payment-priority-billing table thead tr th:nth-child(4) {
  width: 25%;
}

.payment-priority-billing table thead tr th:nth-child(6) {
  text-align: left;
  padding-left: 20px;
}

.payment-priority-billing table tbody tr th:last-child {
  text-align: left;
  padding-left: 10px;
  border-right: none;
}

.payment-priority-billing table tbody tr td:last-child {
  // text-align: left;
  border-right: none;
}

.payment-priority-table-last-section {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 5px 20px 5px 5px;
  background-color: #fafafa;
  border-top: 1px solid #cdced9;
  border-bottom-right-radius: "5px";
}

#payment-priority-table-container {
  border-radius: 5px;
  overflow: hidden;
  border-top: 1px solid #cdced9;
  border-left: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
}

.nacha_field_container {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nach_rfs_wraper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.nacha_btn_wraper {
  display: flex;
  justify-content: end;
  background-color: #f9f9f9;
  padding: 10px;
  border-top: 1px solid #cdced9;
}

.nach_body_container {
  padding: 10px 15px;
}

.batch_action p {
  cursor: pointer;
  padding: 5px 2px;
}

.batch_action p:hover {
  color: #516beb;
}

.add-vendor-form-data {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-vendor-form-submit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.letter-vendor-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.letter-vendor-card-details {
  display: flex;
  align-items: center;
  gap: 40px;
}

.letter-card-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px
}

.letter_vendor_card_container {
  overflow: scroll;
  height: calc(100vh - 200px);
}

.add_discont_slan {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: end;
  margin-top: -10px;
  border-radius: 5px;
  width: 139px;
  margin-left: 115px;
  padding: 5px;
}

.add_discont_slan:hover {
  background-color: #EEEEEE35;
}

.add_discont_slan p {
  color: #516beb;
}

.authentication_container {
  padding: 10px 0px;
  overflow: scroll;
  height: calc(100vh - 185px);
}

.authentication_field_container {
  display: flex;
  gap: 25px;
  padding: 15px 15px 0px 0px;
}

.border-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px 10px 15px 10px
}

.border-line-width {
  border-bottom: 2px solid #c7c7c7;
  width: 100px;
}