/* LAYOUT */
.co-container {
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f8f8fb;
  width: calc(100% - 60px);
  margin-left: 60px;
}

.co-header {
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.co-header_info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.co-header_action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.co-header_action_info {
  display: flex;
  flex-direction: column;
}

/* LAYOUT */

/* CUSTOMER NAME */

.co-customer-name {
  // min-width: 100px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* CUSTOMER NAME */

/* CUSTOMER SECTION */
.co-section {
  display: flex;
  gap: 10px;
  min-width: 0;
  overflow: hidden;
  height: calc(100vh - 60px);
  width: calc(100vw - 80px);
}

.co-section_main {
  flex: 3 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 0px;
}

.co-section_sidebar {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 0;
}

/* CUSTOMER SECTION */

/* INFO CARD */

.co-info-card_wrapper {
  border: 1px solid $border;
  width: 100%;
  border-radius: 5px;
  min-width: 0px;
  overflow: hidden;
}

.co-info-card_main {
  display: flex;
}

.co-info-card {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $border;
  overflow: auto;

  &:nth-last-child(1) {
    border-right: none;
  }
}

.co-info-card_row {
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  min-height: 25px;
}

.co-info-card_cell {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  min-width: 0px;

  &--label {
    background-color: #f9fbff;
    padding-left: 5px;
  }

  &--value {
    background-color: #ffffff;
    justify-content: flex-end;
    padding-right: 5px;
  }

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.co-info-card_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  /*TODO CHANGE*/
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 25px;
  border-top: 1px solid $border;
  gap: 10px;

  &_popover {
    padding: 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
  }
}

.co-info-card_footer_info {
  display: flex;
  gap: 2px;
}

/* INFO CARD */

/* TABLE */
.co-inv-table_header {
  border-top: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  flex: 1;
  background-color: #fafafa;
  padding-right: 4px;
}

.co-inv-table_body-group {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 1px 5px;
  background-color: #ffffff;
  align-items: center;
}

.co-table_header {
  border-top: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  flex: 1;
  background-color: #fafafa;
}

.co-table_header-group {
  display: flex;
  padding: 4.6px 5px;
}

.co-table_header-cell {
  flex: 1;
}

.--payment_modifi {
  padding-right: 22px;
}

.co-invoice-table_wrapper {
  height: 100%;
}

.co-table_body {
  flex: 1 0 0px;
  overflow-x: scroll;
  height: calc(100% - 60px);
  background-color: #ffffff;
}

.co-table_body-group {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 4.6px 5px;
  background-color: #ffffff;
  align-items: center;
}

.co-table_body-cell {
  flex: 1;
}

/* TABLE */

/* INVOICE TABLE */

.co-invoice-section-wrapper {
  display: flex;
  border: 1px solid $border;
  border-radius: 5px;
  flex: 1 0 0;
  overflow: hidden;
}

.co-invoice-section {
  display: flex;
  flex: 1 0 0px;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.co-invoice-section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  background-color: #ffffff;
  height: 40px;
  max-height: 40px;
}

/* INVOICE TABLE */

/* QUICK ACTION */

.co-quick-action-button {
  padding: 4px 2px;
  border-bottom: 1px solid $border;
}

/* QUICK ACTION */

/* PAYMENT TABLE */

.co-payment-card {
  border: 1px solid $border;
  border-radius: 5px;
  flex: 1 0 0px;
  overflow: hidden;
  background-color: #ffffff;
}

.co-payment-card_header {
  padding: 3px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_payment_icon_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* PAYMENT TABLE */

/* ALERT CARD */
.co-alert-card {
  border: 1px solid $border;
  padding: 5px;
  border-radius: 5px;
  max-height: 100px;
  overflow: hidden;
  background-color: #ffffff;
}

.co-alert-card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.co-alert-card_header_info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.co-alert-card_content {
  overflow: hidden;
  max-height: 60px;
}

/* QUICK NOTES */

.co-quick-notes_wrapper {
  background-color: #ffffff;
  border: 1px solid $border;
  border-radius: 5px;
  position: relative;
  height: 178px;
}

.co-quick-notes_wrapper .ql-container {
  border: none;
}

.co-quick-notes_actions {
  position: absolute;
  bottom: 0;
  right: 0;

  &--enlarge {
    top: 0;
  }
}

/* QUICK NOTES */

/* CONTACT */

.co-contact-card_wrapper {
  // min-height: 30px;

  & [data-baseweb="form-control-container"] {
    margin-bottom: -10px !important;
  }
}

.co-contact-card_container {
  padding-top: 15px;
  border: 1px solid $border;
  border-radius: 5px;
  background-color: #ffffff;
}

.co-contact-card_content {
  padding: 5px 5px 5px 5px;
  position: relative;
}

.comment_cancel_button_container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 85px;
}

.comment_cancel_button_container:hover {
  background-color: #eeeeee;
  border-radius: 5px;
}

.co-contact-card_element {
  display: flex;
  gap: 5px;
  align-items: center;
  padding-bottom: 4px;
}

.co-contact-card_footer {
  display: flex;
  border-top: 1px solid $border;
}

.co-contact-card-item {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 5px;
}

.co-contact-card_button {
  background-color: #fafafa;
  border-left: 1px solid $border;
  border-bottom-right-radius: 5px;
  padding: 6.5px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f1f1f1;
  }

  & > p {
    color: $primary;
  }
}

/* CONTACT */

/* TIMELINE */

.co-timeline-section_wrapper {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid $border;
  border-radius: 5px;
  height: 170px;
}

.co-timeline-titile-filter_header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.co-timeline-section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  background-color: #ffffff;
  height: 40px;
  max-height: 40px;
  border-bottom: 1px solid $border;
}

.co-timeline-section_body {
  flex: 1 0 0;
}

.co-timeline-condensed_card {
  display: flex;
  padding: 2px;
  background-color: #ffffff;
  border-bottom: 1px solid $border;
  gap: 2px;
  align-items: center;
}

.co-timeline-condensed_card--alert {
  background-color: #fff4f3;
}

.co-timeline-condensed_element {
  flex: 1 0 0;
  overflow: hidden;

  &--name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  &--info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  &--description {
    flex: 6 0 0;
  }
}

.co-timeline-condensed_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* TIMELINE */

/* ACTION DRAWER */

.co-drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
  padding-right: 25px;
}

.co-drawer-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

/* ACTION DRAWER */

/* CALL ACTION */

.co-call-outcome-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.co-call-outcome-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid $border;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  user-select: none;

  &:hover {
    background-color: #fafafa;
  }

  &--active {
    border: 2px solid $primary;

    & .mx-p {
      color: $primary;
    }

    & .mx-icon path {
      fill: $primary;
    }
  }

  &--disabled {
    pointer-events: none;

    & .mx-p {
      color: $border;
    }

    & .mx-icon path {
      fill: $border;
    }
  }

  &--fit {
    height: fit-content;
  }
}

.co-call-outcome-btn-unsucessful {
  &--active {
    border: 2px solid red;

    & .mx-p {
      color: red;
    }

    & .mx-icon path {
      fill: red;
    }
  }
}

.co-cloud-call__phonenumber {
  cursor: pointer;
}

.co-prv-nxt_wraper {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* CALL ACTION */

//  contact SCSS

.add_contact_action_wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.add_contact_icon_container {
  display: flex;
  align-items: center;
}

.co-payment-table-cointer {
  width: 800px;
}

.co-payment-table_wrapper {
  border: 1px solid #cdced9;
  border-top: 0px;
  border-radius: 5px;
  overflow: hidden;
}

.co-add-payment-container {
  width: 820px;
}

.co_add_payment_top_input_wraper {
  display: flex;
  align-items: center;
  margin-top: 12px;
  gap: 10px;
}

.add-client-container_scroll {
  background: #ffffff;
  padding: 20px;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.add-state-disclosure_scroll {
  background: #ffffff;
  padding: 20px;
  height: calc(100vh - 150px);
  overflow-y: hidden;
  overflow-x: hidden;
}

.add-client-container_disclosure_scroll {
  background: #ffffff;
  padding: 20px;
  height: calc(100vh - 150px);
}

.agent_instruction_container {
  width: 750px;
  max-width: 750px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--no_instruction {
    height: auto;
  }

  & .agent_instruction_alert--no_instruction {
    border-bottom: 1px solid $border;
  }
}

.agent_instruction_header {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $border;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.agent_instruction_alert {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 2px 5px;

  &--no_instruction {
    gap: 10px;
    padding: 10px;
  }
}

.agent_instruction_title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 15px;

  &--no_instruction {
    justify-content: center;
  }
}

.client_agent_instruction_both {
  width: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  padding: 10px;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mini_instruction_both {
  width: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
  padding: 10px;
  height: 350px;
}

.agent_instruction_content {
  margin: 10px 15px;
  white-space: pre-wrap;
}

.agent_instruction_single_content {
  margin: 10px 15px;
  white-space: pre-wrap;
  overflow-y: auto;
  height: 350px;
  width: 700px;
  overflow-x: hidden;
  word-wrap: break-word;
}

.agent_instruction_footer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.co-action-alert {
  display: flex;
  flex-direction: column;
  background-color: #f0f5ff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.co-action-alert-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.co-action-alert-actions {
  display: flex;
  gap: 5px;
}

.co-action-alert-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  & p {
    color: $primary;
  }
}

.co-action-alert-content {
  margin-top: 10px;
  white-space: pre-wrap;
}

.co_action_client_inst {
  margin-top: 10px;
  margin-left: 10px;
}

.co_altc_payment_table_header {
  border-bottom: 1px solid #cdced9;
  flex: 1;
  background-color: #fafafa;
}

.co_altc_payment-table_wrapper {
  flex: 1 0 0px;
  height: calc(100% - 40px);
  border: 1px solid #cdced9;
}

.co_inv_types_btn {
  display: flex;
  border: 1px solid #516beb;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 11px 15px 10px 15px;
  cursor: pointer;
  color: #516beb;
}

.co_inv_types_btn p {
  color: #516beb;
}

.co_inv_types_btn:hover {
  background-color: #cdced925;
}

.co_expand_container {
  flex: 1;
  background-color: #ffffff;
  // height: 100%;
  margin-right: 10px;
}

.co_sub_container {
  flex: 1;
}

.co_sub_container--expand {
  flex: 3;
  height: 100%;
  overflow: scroll;
}

.co_section--modifier {
  flex: 1 0 0;
  display: flex;
  gap: 10px;
  min-width: 0;
  overflow: hidden;
  flex-direction: column;
}

.co_container--scroll {
  // padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 5px);
  background-color: #f8f8fb;
  width: calc(100% - 60px);
  overflow: hidden;
  padding-top: 5px;
  padding-right: 5px;
  margin-left: 60px;
}

.co_expand_container--modifier {
  display: none;
}

.co_customer_expand_container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0px;
}

.co_expand_list_icon_container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.co_expand_list_icon_container svg {
  width: 18px;
  height: 18px;
}

.co_expand_list_icon_container svg path {
  fill: #333860;
}

.co_expand_icon_wraper {
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 3px;
}

.co_custmer_active {
  background-color: #f0f5ff;
}

.co_custmer_active .co_expand_list_icon_container svg path {
  fill: #516beb;
}

.co_custmer_active .co_expand_icon_wraper {
  background-color: #cedfff;
}

.type-pause_reason {
  color: #fd372a;
}

.co-visit-status-container {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.--co_more_info_modifier {
  position: relative;
}

.co_more_info {
  position: absolute;
  right: 2%;
  bottom: 3%;
  color: #516beb;
  cursor: pointer;
}

.co_timeline_card_container {
  width: 600px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(51, 56, 96, 0.25);
  margin-bottom: 10px;
  overflow: hidden;
}

.co_timeline_view_card_container {
  width: 600px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.view-condensed-timline {
  display: none;
}

.co_timeline_dispute_forward_container {
  display: none;
}

.co-timeline-condensed_element:hover .view-condensed-timline {
  display: block;
  background-color: #f0f5ff;
  cursor: pointer;
}

.co-timeline-condensed_element:hover .co_timeline_dispute_forward_container {
  display: block;
  // background-color: #f0f5ff;
  cursor: pointer;
}

.co_timeline_header_container {
  padding: 10px;
}

.co_timeline_header {
  display: flex;
  gap: 5px;
  align-items: center;
}

.co_timeline_comment_wraper {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-top: 5px;
}

.co_timeline_amount_wraper {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.co_timeline_date_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.co_timeline_referance_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.co_payment_plan_content {
  margin-top: 5px;
}

.co_workflow_content {
  margin-top: 5px;
}

.lable_contain {
  max-width: 150px;
  min-width: 90px;
}

.co_timeline_footer_contain {
  display: flex;
  justify-content: center;
  background-color: #fafafada;
  border-top: 1px solid #cdced9;
}

.co_timeline_card_right_container {
  width: 500px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(51, 56, 96, 0.25);
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  /* Align the container to the right */
}

.co_timeline_header_right_container {
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  /* Align the header container to the right */
}

.co_timeline_comment_right_wraper {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-top: 5px;
  flex-direction: row-reverse;
  /* Align the comment wrapper to the right */
}

.co_timeline_footer_right_contain {
  display: flex;
  justify-content: center;
  background-color: #fafafada;
  border-top: 1px solid #cdced9;
  flex-direction: row-reverse;
  /* Align the footer container to the right */
}

.co_timeline_amount_right_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  flex-direction: row-reverse;
  /* Align the amount wrapper to the right */
}

.co-review-promise {
  display: flex;
  flex: 1 0 0px;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  width: 650px;
}

.co-review-promise-table_header-group {
  display: flex;
  padding: 4.6px 5px;
  gap: 5px;
}

.co-review-promise-inv-table_body-group {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 1px 5px;
  background-color: #ffffff;
  align-items: center;
  gap: 5px;
  position: relative;
}

.co-rp-action-button {
  position: absolute;
  display: flex;
  right: 1%;
}

.co-rp-modifi {
  display: flex;
  align-items: center;
}

.co-rp-table_header {
  border: 1px solid #cdced9;
  flex: 1;
  background-color: #fafafa;
  padding-right: 4px;
  border-radius: 5px 5px 0px 0px;
}

.co-rp-table_body {
  flex: 1 0 0px;
  overflow-x: scroll;
  height: calc(100% - 110px);
  background-color: #ffffff;
  border-left: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.co-timeline-list_container {
  height: calc(100vh - 105px);
  overflow: scroll;
  margin-top: 10px;
  width: 610px;
}

.co-inv-table-edit {
  position: absolute;
  right: 0%;
  display: none;
}

.--modifi_edit {
  position: relative;
}

.--modifi_edit:hover .co-inv-table-edit {
  display: inline-block;
}

.co_remove_group {
  margin-top: 7px;
  position: absolute;
  right: 1%;
  display: none;
}

.co_process_group_body_wraper {
  position: relative;
  display: flex;
  border: 1px solid #cdced9;
  border-bottom: 0px;
  gap: 10px;
}

.co_process_group_body_wraper:hover .co_remove_group {
  display: block;
}

.co_groping_delete_popover {
  width: 250px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cdced9;
  border-radius: 5px;
}

.co_groping_delete_callback_popover {
  width: 280px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid #cdced9;
  border-radius: 5px;
}

.co_groping_delete_popover_footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 8px;
  margin-top: 10px;
}

.co_groping_delete_popover_body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #cdced9;
  padding: 0px 0px 10px 0px;
  justify-content: flex-start;
  align-items: flex-start;
}

.co_groping_delete_call_back_popover_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  justify-content: center;
}

.co_pr_grouping_bottom_line {
  border: 1px solid #f1f1f1;
  margin-bottom: 0px !important;
}

.co_action_exist {
  color: #fd372a;
  text-align: end;
}

.co_timeline_email_reply_container {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 14%;
  background-color: #ffffff;
  border-radius: 5px;
}

.--view_icon_postion {
  right: 15%;
}

.--timeline_wraper_position {
  position: relative;
}

.co_timeline_reply_action {
  display: none;
}

.--timeline_wraper_position:hover .co_timeline_reply_action {
  display: inline-block;
}

.co-timeline-condensed_card:hover .view-condensed-timline {
  display: inline-block;
}

.co_inv_sub_header {
  padding: 5px;
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}

.co_inv_sub_header p {
  font-size: 12px;
  color: #adadad;
  padding-left: 15px;
}

.co_action_inv {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 2px;
}

.co_action_inv:hover {
  background-color: #516beb30;
  border-radius: 5px;
}

.co_action_inv p {
  font-weight: 400;
  font-size: 12px;
}

.co_legal_action_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeeeee;
  margin-bottom: 10px;
}

.co_legal_action_title_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-def_input {
  display: flex;
  align-items: center;
}

.select-def_input:hover {
  background-color: #f0f5ff;
}

.select-debts-invoice_input {
  cursor: pointer;
  padding: 5px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.legal-info-plus_icon {
  display: flex;
  padding: 10px;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.legal-info-plus_icon:hover {
  background-color: #f0f5ff;
}

.dot_legal_action {
  height: 5px;
  width: 5px;
  background-color: #d3d3d7;
  border-radius: 50%;
}

.legal_action_motion_sub_text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legal_action_motion_container {
  margin-right: 10px;
}

.legal_action_custom_container {
  flex: 1;
  padding-right: 10px;
  width: 40%;
}

.legal_info_action_container {
  width: 504px;
  flex: 1;
  border-left: 1px solid #eeeeee;
  padding-left: 10px;
  width: 60%;
}

.co_legal_action_body_container {
  display: flex;
  width: 100%;
}

.counsel_icon_container {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #dde8ff;
}

.add_saved_pay_cards {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f5ff;
}

.legal_action_counsel_container {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f3f7ff;
  border-radius: 5px;
  padding: 10px;
  // margin-top: 10px;
  cursor: pointer;
}

.legal_info_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.legal_info_tab {
  padding: 5px 5px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
  margin-bottom: 10px;
}

.legal_info_tab_container {
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.--modifi_legal_info_tab {
  border-top: 0px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  border-right: 0px solid #cdced9;
  border-left: 0px solid #cdced9;
  border-radius: 0px;
}

.legal_info_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-top: 1px solid #cdced9;
  background-color: #fafafa;
}

.legal_info_footer_content {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.co-notes_wrapper {
  background-color: #ffffff;
  // border-top: 1px solid $border;
  // border-bottom: 1px solid $border;
  position: relative;
  height: 160px;
}

.legal-notes-section {
  border-top: 1px solid #cdced9;
  position: relative;
}

.co_note_container {
  border: 1px solid #cdced9;
  border-radius: 5px;
  // overflow: hidden;
}

.co-quick-notes_actions_remove {
  position: absolute;
  bottom: 0;
  right: 4%;

  &--enlarge {
    top: 0;
  }
}

// Judgement Edit Table

.judge-ment-table-wrapper {
  border: 1px solid #eeeeee;
  margin: 10px;
  border-radius: 5px;
}

.judgemet-table_header-group {
  display: flex;
  padding: 13px 20px;
}

.judgement-table-header {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}

.judge-ment_table-group {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 1.6px 10px;
  background-color: #ffffff;
  align-items: center;
}

.legal-notes-actions {
  position: absolute;
  bottom: 1%;
  right: 2%;

  &--enlarge {
    top: 0;
  }
}

.legal-notes-remove {
  position: absolute;
  bottom: 1%;
  right: 6%;

  &--enlarge {
    top: 0;
  }
}

.legal_info_footer_content p {
  cursor: pointer;
}

.doc_title_els {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi_select_access div {
  margin-bottom: 0px !important;
}

.multi_select_access {
  width: 180px;
}

// .motion-count-ripple {
//   text-align: center;
//   color: #ffffff;
//   border: 3px solid #dde3ff;
//   width: 23px;
//   border-radius: 9px;
//   background-color: #516beb;
// }
.motion-count-ripple {
  background: #516beb;
  text-align: center;
  color: #ffffff;
  border: 1px solid #dde3ff;
  font-size: 10px;
  padding: 2px 4px;
  line-height: 12px;
  border-radius: 5px;
  -webkit-animation: throb 1.5s infinite ease-in-out;
  animation: glow 1.5s infinite ease-in-out;
  position: relative;
}

.motion-count-ripple::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: inherit;
  border-radius: 50%;
  z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  background-color: #516beb;
  right: 0;
}

@-webkit-keyframes glow {
  0% {
    -webkit-box-shadow: 0px 0px 15px 8px #0029f3;
  }

  50% {
    -webkit-box-shadow: 0px 0px 15px 5px #516beb;
  }

  100% {
    -webkit-box-shadow: 0px 0px 15px 5px #002aff;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #516beb80;
  }

  50% {
    box-shadow: 0 0 5px #516beb;
  }

  100% {
    box-shadow: 0 0 5px #516beb80;
  }
}

.ins_suggestion p {
  background-color: #fff0f0;
  color: #fd372a;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

//collection-dashboard
.future-payments-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  box-shadow: 0px 8px 24px 0px #959da520;
}

.future-payments-header-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.future-payments-header-separator {
  display: flex;
  justify-content: space-between;
}

.future-payments-type_selector {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.future-payments-payment-collected {
  display: flex;
  gap: 3px;
  align-items: center;
}

.future-payments-tooltip-expected {
  background-color: #16164b;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.future-payments-toolTip_smallBox {
  width: 10px;
  height: 10px;
  border-radius: 2.5px;
}

.co_table_inv_des {
  background-color: #fffaeb;
  padding: 3px 15px;
}

.wf_active {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #516beb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wf_active::after {
  content: "";
  position: absolute;
  width: 55%;
  height: 55%;
  background-color: inherit;
  border-radius: 50%;
  z-index: 0;
  animation: ripple 1.5s ease-out infinite;
  background-color: #516beb;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(3);
  }
}

.--adhoc_td_body {
  position: relative;
}

.adhoc_upload_delete {
  position: absolute;
  right: 4%;
  top: 30%;
}

.doc_quick_view_container {
  display: flex;
  align-items: center;
  border: 1px solid #cdced9;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 3px;
  width: 48%;
  cursor: pointer;
}

.doc_file_name {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc_wraper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.doc_cus_name {
  white-space: nowrap;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table_nest_container:last-child {
  color: #adadad;
}

.nacha_fields_container {
  background-color: #fafafa;
  border: 1px solid #cdced9;
  padding: 0px 25px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.upload_pay_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.upload_pay_wraper p {
  color: #516beb;
}

// customer lock
.userlock-main-popover-container {
  text-align: center;
  padding-left: 50px;
  padding-top: 40px;
}

.userlock-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;
  width: 175px;
  height: 157px;
  margin-left: 35px;
}

//tooltip lockec customer
.customer-header-locked_tooltip {
  background-color: #16164b;
  display: flex;
  align-items: center;
  gap: 3px;
}

.co_sticky_note {
  border: 1px solid #cdced9;
  border-radius: 5px;
  height: 178px;
  background-color: #ffffff;
  position: relative;
}

.co_sticky_note_wraper {
  border: 1px solid #cdced9;
  border-radius: 5px;
  // overflow: hidden;
  height: 178px;
  background-color: #ffffff;
  position: relative;
}

.co_sticky_note_action_icn {
  position: absolute;
  bottom: 2%;
  left: 1%;
}

.co_sticky_note_action_icn_note {
  position: absolute;
  bottom: 2%;
  left: 7%;
}

.co_sticky_con {
  position: absolute;
  bottom: -25%;
  left: 1%;
}

.co_sticky_con--m_m {
  position: absolute;
  bottom: 22%;
  left: 1%;
}

.co_note_action_icn {
  position: absolute;
  bottom: -7%;
  left: 1%;
}

.co_sticky-notes-actions {
  position: absolute;
  bottom: 2%;
  right: 1%;
}

.co_sticky-notes-actions-sub {
  position: absolute;
  bottom: 2%;
  right: 1%;
}

.co_sticky-notes-remove {
  position: absolute;
  bottom: -7%;
  right: 6%;
}

.co_sticky-notes-actions--m {
  position: absolute;
  bottom: -25%;
  right: 1%;
}

.co_sticky-notes-actions--m_m {
  position: absolute;
  bottom: 22%;
  right: 1%;
}

.co_sticky-notes-remove--m {
  position: absolute;
  bottom: -25%;
  right: 6%;
}

.co_sticky-notes-remove--m_m {
  position: absolute;
  bottom: 22%;
  right: 6%;
}

.drawer_body_container {
  position: relative;
}

.sticky_note_data_list {
  background-color: #333860;
  position: absolute;
  bottom: 3%;
  left: 5%;
  border-radius: 5px;
  z-index: 1;
}

.drawer_sticky {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #ffffff;
}

.drawer_sticky p {
  color: #ffffff;
  font-weight: 400;
}

.sticky_list {
  color: #ffffff;
  width: 300px;
  font-weight: 400;
  padding: 5px 10px;
}

.sticky_list span {
  color: #8f909a;
  font-size: 11px;
}

.--sticky_list_modifi {
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  width: 300px;
  font-weight: 400;
  padding: 5px 10px;
}

.--sticky_list_modifi span {
  color: #8f909a;
  font-size: 11px;
}

.delete_wraper {
  position: relative;
}

.sticky_delete_btn {
  position: absolute;
  right: 0;
  top: 10%;
  display: none;
}

.delete_wraper:hover .sticky_delete_btn {
  display: inline;
}

.sticky_notes_list_container {
  // min-height: 80px;
  max-height: 200px;
  overflow: scroll;
}

.sticky_alert_dot {
  position: absolute;
  bottom: 1%;
  left: 10%;
}

.--sticky_editor_container {
  background-color: #ffffff;
  height: calc(100% - 70px);
}

.--modifi_sticky_wraper {
  background-color: #ffffff;
  height: calc(100% - 0px);
}

.adhoc_dnl {
  color: #787878;
  display: flex;
  align-items: center;
  gap: 5px;
}

.--client_inst {
  white-space: unset;
}

.adj_table {
  width: 100%;
  border-spacing: 0px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #cdced9;
}

.adj_table_row td {
  text-align: left;
  padding: 8px;
  border: 1px solid #cdced9;
  border-spacing: 0px;
}

.adj_span {
  color: #787878;
}

.adj_content_wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adj_timeline_wraper {
  position: relative;
}

.adj_timeline_wraper:hover .adj_timeline_icon_wraper {
  display: inline;
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 5%;
  right: 3%;
}

.adj_timeline_wraper .adj_timeline_icon_wraper {
  display: none;
}

.adj_timeline_icon_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 5%;
  right: 3%;
}

.adj_con_timeline_wraper {
  position: relative;
}

.adj_con_timeline_icon_wraper {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 10%;
  right: 1%;
}

.adj_con_timeline_wraper .adj_con_timeline_icon_wraper {
  display: none;
}

.adj_con_timeline_wraper:hover .adj_con_timeline_icon_wraper {
  display: inline;
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 10%;
  right: 1%;
}

.adj_past_list_icon_wraper {
  display: flex;
  gap: 5px;
  align-items: center;
  border: 0px;
  justify-content: center;
}

.adj_popover_head_container {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  margin-bottom: 5px;
}

.adj_popover_container {
  background-color: #ffffff;
  text-align: center;
  padding: 15px 12px 15px 12px;
  border-radius: 5px;
}

.adj_popover_footer_container {
  margin-top: 10px;
}

.itmzn_err td {
  color: #fd372a;
}

.itmzn_err span {
  color: #fd372a;
}

.itmzn_err_alert_icon {
  background-color: #ffe9e9;
  border-radius: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
}

.itmzn_err_alert_icon p {
  font-size: 11px;
  font-weight: 400;
  color: #fd372a;
}

.itmzn_err_alert {
  display: flex;
  align-items: center;
}

.--err_itzm_col {
  color: #fd372a;
}

.float-next-button {
  position: absolute;
  top: 56%;
  left: 98%;
  z-index: 0;
  right: 10px;
  margin-right: -10px;
  opacity: 0.55;
  // width: 40px;
  // height: 60px;
  // bottom: 40px;
  // right: 40px;
  background-color: var(--sidebarbg);
  // box-shadow: 3px 3px 3px #999;
  // color: #fff;
  border: 2px solid #2a3042;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  // text-align: center;
  // box-shadow: 2px 2px 3px #999;
}
