.payment-card {
  width: 842px;
}
.payment-btn {
  flex: 0;
}
.skip-trail {
  display: none;
}
.top-skip {
  display: block;
}
.boxShadow {
  right: -9%;
}

// .ribbon {
//   height: 130px;
//   position: relative;
//   /*   margin-bottom: 30px; */
//   background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
//   background-size: cover;
//   text-transform: uppercase;
//   color: white;
// }

.ribbon3 {
  width: 130px;
  height: 25px;
  // line-height: 50px;
  padding-left: 15px;
  position: absolute;
  left: -8px;
  top: 20px;
  background: linear-gradient(to right, #e5ab18, #dc4638);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ribbon3:before,
.ribbon3:after {
  content: "";
  position: absolute;
}
.ribbon3:after {
  height: 0;
  width: 2px;
  right: 0px;
  bottom: -9px;
  border-top: 9px solid #989898;
  border-right: 9px solid transparent;
}
.ribbon3:before {
  height: 0;
  width: 0;
  left: 0px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  // border-left: 10px solid #ffffff;
  border-left: 5px solid #333860;
  margin-top: 0px;
}

.ribbon4 {
  width: 130px;
  height: 25px;
  // line-height: 50px;
  padding-left: 15px;
  position: absolute;
  left: -8px;
  top: 20px;
  background: linear-gradient(to right, #e5ab18, #dc4638);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ribbon4:before,
.ribbon4:after {
  content: "";
  position: absolute;
}
.ribbon4:after {
  height: 0;
  width: 2px;
  right: 0px;
  bottom: -9px;
  border-top: 9px solid #989898;
  border-right: 9px solid transparent;
}
.ribbon4:before {
  height: 0;
  width: 0;
  left: 0px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ffffff;
  margin-top: 0px;
}

@media (min-width: 300px) and (max-width: 800px) {
  .payment-card {
    width: 275px;
  }
}

@media (min-width: 300px) and (max-width: 800px) {
  .payment-btn {
    flex: 1;
  }
}

@media (min-width: 300px) and (max-width: 800px) {
  .skip-trail {
    display: block;
    margin-bottom: 10%;
  }
}

@media (min-width: 300px) and (max-width: 800px) {
  .top-skip {
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 800px) {
  .boxShadow {
    right: -7%;
  }
}
