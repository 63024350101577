.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f3f8;
  border-radius: 5px;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  border-style: dashed;
  border-width: 1px;
  border-color: #cdced9;
  border-spacing: 5px;
}

.--drop_modifi {
  padding: 15px 20px;
  flex-direction: row;
}

.dropzone-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f3f8;
  border-radius: 15px;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  border-style: dashed;
  border-width: 1px;
  border-color: #cdced9;
  border-spacing: 5px;
  height: 300px;
}

.container-adhoc {
  padding: 0px 75px;
}

.--predefind_modifier {
  padding: 0px 30px;
}

.adhoc-table__wrapper {
  display: table-caption;
  text-align: center;
  font-size: 20px;
  position: sticky;
  top: 0;
}

.adhoc-table_1__wrapper {
  text-align: center;
  font-size: 20px;
  position: sticky;
  top: 0;
}

// .adhoc-table__header {
//   flex-direction: column;
//   background-color: #f5f6fa;
//   font-size: 20px;
//   border: 1px solid #cdced9;
//   overflow: hidden;
// }
.adhoc-table__header-content {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}

.adhoc-table__header-cell-content {
  height: 100%;
  display: flex;
  position: relative;
  // flex: 1 1;
}

.adhoc-table__header-cell {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adhoc-table__header-cell_group_contact {
  width: 50px !important;
  padding-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adhoc-table__body-wraper {
  display: table-row-group;
}

.adhoc-table__cell-header {
  flex-direction: column;
  background-color: #f5f6fa;
  font-size: 20px;
  border: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.adhoc-table__body-content {
  border-bottom: 1px solid #cdced9;
  border-left: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  height: 35px;
}

.adhoc-table__body-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  border-right: 1px solid #cdced9;
}

.adhoc-table__body-cell_group_contact {
  width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .adhoc-table__title-section {
//   // display: flex;
//   // width: 1000px;
// }
.adhoc-table__title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px 0px 10px;
}

.animate-circle {
  background: #0fb158;
  width: 7px;
  height: 7px;
  margin-left: 5px;
  margin-right: 3px;
  margin-top: 1%;
  border-radius: 50%;
  -webkit-animation: throb 1.5s infinite ease-in-out;
  animation: glow 1.5s infinite ease-in-out;
}

// Additional Settings

.adhoc-additional-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  width: 300px;
}

.adhoc-additional-footer {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.typo-group-contact {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
}

// .animate-circle::before {
//   content: "";
//   position: absolute;
//   width: 10px;
//   height: 10px;
//   background-color: inherit;
//   border-radius: 50%;
//   z-index: -1;
//   animation: ripplecircle 1.5s ease-out infinite;
//   background-color: #516beb;
// }

@-webkit-keyframes glow {
  0% {
    -webkit-box-shadow: 0px 0px 15px 5px #0fb15850;
  }

  50% {
    -webkit-box-shadow: 0px 0px 15px 2px #0fb158;
  }

  100% {
    -webkit-box-shadow: 0px 0px 15px 2px #0fb15850;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #0fb15850;
  }

  50% {
    box-shadow: 0 0 5px #0fb158;
  }

  100% {
    box-shadow: 0 0 5px #0fb15850;
  }
}

.--label-style {
  left: 2.75rem;
}

.accepten_file_container {
  height: 25px;
  border: 1px solid #cdced9;
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 5px;
  justify-content: space-between;
  padding: 7px;
}

.upload_file_name {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload_field_wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 30px;
}

//File deleted

.file-deleted-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file-deleted-container-header {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.verify-deletion {
  display: flex;
  gap: 10px;
  padding-left: 33px;
}

.adhco_style--xlsx {
  top: 11px;
  left: 6px;
  cursor: pointer;
}

.adhco_style {
  top: 11px;
  cursor: pointer;
}

.cursor-point {
  cursor: pointer;
}

.cursor-point:hover {
  background-color: #cdced9;
  border-radius: 5px;
}