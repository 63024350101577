.mx-input {
  position: relative;
  font-size: 16px;
  z-index: 0;
}

.mx-input__label {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  font-weight: normal;
  transition: all 0.4s ease;
  padding: 0 0.2rem;
  font-size: 14px;
  color: #adadad;
  transform: translateY(-50%);
  line-height: 5px;
}

.mx-input__label--select {
  z-index: 1;
}

.mx-input__label--active {
  top: 0%;
  font-size: 12px;
  background: #fff;
}

.mx-input_rule__label {
  position: absolute;
  left: 0.75rem;
  top: 74%;
  font-weight: normal;
  transition: all 0.4s ease;
  padding: 0 0.2rem;
  font-size: 14px;
  color: #adadad;
  transform: translateY(-50%);
  line-height: 5px;
}

.mx-input_rule__label--select {
  z-index: 1;
}

.mx-input_rule__label--active {
  top: 0%;
  font-size: 12px;
  background: #fff;
}

.ta-input__label {
  position: absolute;
  left: 0.75rem;
  top: 20%;
  font-weight: normal;
  transition: all 0.4s ease;
  padding: 0 0.2rem;
  font-size: 14px;
  color: #adadad;
  transform: translateY(-50%);
}

.ta-input__label--select {
  z-index: 1;
}

.ta-input__label--active {
  top: 0%;
  font-size: 12px;
  background: #fff;
}
