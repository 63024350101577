.mx-chip-filter {
  height: 28px;
  padding: 5px 10px;
  border: 1px solid #cdced9;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;

  color: #adadad;
  margin-right: 5px;
  cursor: pointer;
  user-select: none;
}

.mx-chip-filter_new {
  height: 28px;
  padding: 5px 10px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;

  color: #adadad;
  margin-right: 5px;
  cursor: pointer;
  user-select: none;
}

.mx-chip-filter__start-enhancer {
  padding-right: 5px;
}

.mx-chip-filter--active {
  border: 1px solid var(--primary);
  color: var(--primary);
  font-weight: bold;
  background-color: #f2f5ff;
}

.mx-chip-filter_new--active {
  border: 1px solid var(--primary);
  color: var(--primary);
  font-weight: bold;
  background-color: #f2f5ff;
}
