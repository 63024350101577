// on cha
.iphone-x {
  position: relative;
  margin: 40px auto;
  width: 360px;
  height: 780px;
  background-color: #fff;
  // background-image: linear-gradient(60deg, #7371ee 1%, #a1d9d6 100%);
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .top-phone {
    position: relative;
    height: 30px;
  }

  // home button indicator
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }

  // frontal camera/speaker frame
  &:before {
    top: 0px;
    width: 54%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
  }

  .i,
  b,
  s {
    position: absolute;
    display: block;
    color: transparent;
  }


  // speaker
  .i {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }

  // camera
  b {
    left: 10%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);


  }

  // time
  s {
    top: 8px;
    left: 33px;
    color: #000;
    text-align: center;
    text-decoration: none;
    /* width: 100%; */
    font-size: 14px;
    font-weight: 500;
  }

  // action buttons

}

.customize-mobile {
  display: flex;
  overflow: scroll;
  height: calc(100% - 60px);
  // padding: 25px 30px;
  margin: 0px;
  justify-content: space-around;
}

.customer_preview-header {

  padding: 7px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}

.customer_preview-digit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  gap: 10px;
  width: 360px;
  height: 55px;
  background: #FFFFFF;
  box-shadow: 0px -1px 5px rgba(51, 56, 96, 0.2);
}

.customer_preview-icons {
  border: 1px solid #C7C7C7;
  padding: 7px 8px 6px;
  border-radius: 5px;
  cursor: pointer;
}


.customer-preview-invoice {
  background-color: #fff;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  //  border: 1px solid #;
  gap: 30px;

  align-items: center;
  padding: 7px 7px 0px 0px;
  margin: 6px 7px 0px;
  cursor: pointer;
  // border-bottom: 1px solid gray;   
}

.customer_preview-card {
  border: 0.5px solid #CDCED9;
  padding: 9px;
  border-radius: 5px;
}

.customer_preview-card_bottom {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 9px 0px;
}

.mx-icon-view-less1 {
  font-size: 24px;
}

.mx-icon-expand_more {
  font-size: 24px;
}

.mx-icon-view-less1::before {
  color: var(--logo-color) !important;
}

.mx-icon-expand_more::before {
  color: var(--logo-color) !important;
}

.mx-icon-checkbox-select {
  font-size: 16px;
}

.mx-icon-checkbox-select::before {
  color: var(--logo-color) !important;
}

.mx-icon-check-box-unselect {
  font-size: 16px;
}

.mx-icon-Vector-4 {
  font-size: 14px;
}

.mx-icon-Vector-3 {
  font-size: 14px;
}

.mx-icon-Vector-2 {
  font-size: 14px;
}

.customer_preview-card_hidden-part {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
}

.customer_preview-card_payment {
  padding: 5px 24px;
  border-radius: 5px;
  border: 1px solid #cdced9;
  cursor: pointer;
  width: 133px;
  text-align: center
}

.customer_preview-card_invoice {
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  width: 123px;
  // border: 1px solid;
  text-align: center
}

.customer-grouping-recent--header-popover:hover {
  background-color: #F0F5FF;
}

.customer_preview-summary {
  // background-color: #fff;
  padding: 25px 19px;
  height: 210px;
  transform: translateY(-55px);
  // border-radius: 10px;
  background: #FFFFFF;
  border: 0.5px solid #CDCED9;
  box-shadow: 0px -1px 5px rgba(51, 56, 96, 0.2);
  border-radius: 25px 25px 0px 0px;
  box-shadow: rgb(51 56 96 / 14%) 0px 0px 13px 0px;
}

.customize-ui-welcome-text {
  text-align: center;
  margin-bottom: 10px;
}

.welcome-text-inner-style div {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.welcome-text-inner-style span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.customixe-ui-summary-container {
  padding: 10px;
}

.customize-ui-welcome-text-amt {
  text-align: center;
  margin-bottom: 15px;
}

.summery-action-view-btn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.summery-action-view-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icon-border {
  border: 1px solid #CDCED9;
  border-radius: 50%;
  padding: 10px;
}

.customixe-ui-summary-upload-icon {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
}

.customize-ui-compliance-text {
  text-align: center;
  margin-top: 20px;
}

.summery-action-view-labels {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 5px 5px 20px;
}