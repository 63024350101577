// .layout-container {
// display: flex;
// }

.content-container {
  display: flex;
  // width: calc(100% - 60px);
  height: 100vh;
  flex-direction: column;
  padding: 25px 25px 0px 25px;
  margin-left: 60px;
}
.co-contact-card_content {
  padding: 5px 5px 5px 5px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 227px;
}
.content-container--all-side-padding {
  padding: 25px 25px 25px 25px;
}

.content-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.content-header__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.content-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.report-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.invoice-overview__hight-fix {
  height: 89% !important;
}

.--settings__layout-changr {
  flex-direction: row;
}

.start__pack {
  height: 5vh;
  background-image: linear-gradient(to right, #516beb, #4f56df);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.--layout_modifier .sidebar-wrapper {
  height: 95vh;
}

.--layout_modifier .content-container {
  height: 95vh;
}

.layout__start__wraper {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.upgrade__btn {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.book__demo__tag {
  position: absolute;
  right: 3%;
}
