.invoice-alain {
  margin-top: 16px;
}

.--invoice_head {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.inv-overdue__red {
  height: 8px;
  width: 8px;
  background-color: #fd372a;
  border-radius: 20px;
  margin-right: 5px;
}

.inv-status__container-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inv-card__download {
  text-align: center;
  margin-top: 15px;
}

.invoice-body--customer-overview {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.invoice-profile-card {
  background-color: #fff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0 #33386025;
  grid-column: 1/2;
  grid-row: 1/7;
  height: 100%;
  min-width: 350px;
  overflow: hidden;
}

.invoice-profile__card-content {
  height: 100%;
  overflow: scroll;
}

.invoice-profile__card-due-content {
  padding-left: 20px;
  padding-right: 20px;
}

.invoice-card__relation-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.inv-card__relation-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.inv-card__workflow-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.inv-card__workflow-header {
  color: #adadad;
}

.inv-card__workflow-edit {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: end;
}

.inv-profile-tab {
  background: #fff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0 #33386025;
  grid-column: 2/5;
  grid-row: 1/7;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.--adhoc-apl {
  position: relative;
}

.--adhoc-apl-wraper {
  position: absolute;
  left: 1%;
  height: 40px;
  border-right: 1px solid #cdced9;
}

.--adhoc-delete-btn {
  position: absolute;
  right: 0;
  height: 40px;
  z-index: 1;
  top: 10%;
}

.--inv_summery_tabel_body {
  position: relative;
}

.inv_edit_btn_Wraper {
  position: absolute;
  right: 3%;
}

.--inv_delete_modifi{
  right: 0%;
}

.--inv_delete_modifi .hover_color_dlt{
  color: #fd372a;
}

.--inv_summery_tabel_body .inv_edit_btn_Wraper {
  display: none;
}

.--inv_summery_tabel_body:hover .inv_edit_btn_Wraper {
  display: block;
}

.--inv_summery_tabel_body .inv_view_btn_Wraper {
  display: none;
}

.--inv_summery_tabel_body:hover .inv_view_btn_Wraper {
  display: block;
}

.inv_view_btn_Wraper {
  position: absolute;
  right: 5%;
}
