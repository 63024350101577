.tableFixHead {
  overflow-y: scroll;
  height: 280px;
  width: 100%;
  margin-left: 3px;
  margin-right: 4px;
}

.table_main_body {
  height: 60%;
  overflow-y: auto;
}

.planned_amount-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4%;
}

.table_main_header {
  position: sticky;
  top: -3px;
  background: #ffff;
}

.--test_index {
  z-index: 1;
}

.table_main {
  border-collapse: collapse;
  width: 100%;
}

.table_header {
  color: #333860;
  border-left: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
  height: 20px;
}

.table_body {
  color: #333860;
  border: 1px solid#CDCED9;
  height: 35px;
}

.table_header {
  background: #ffff;
  //   padding-bottom: 25px;
}

.submissiondays {
  height: 30px;
  border-top: 1px solid #cdced9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stair_step_header_table {
  //   padding-bottom: 17px;
  transform: translateY(-12px);
  text-align: center;
}

.stair_step_cell_value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.--ss_aligment{
  justify-content: right;
}

.--ss_body_mx div{
 padding: 0px 5px;
}

// Tabel