//Bankruptcy Manitenance Customer Overview

.bankruptcy-drawer-container {
  width: 600px;
}

.bankruptcy-drawer-container-header {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bankruptcy-drawer-container-form {
  position: relative;
}

.bankruptcy-drawer-container-footer {
  text-align: end;
}

.ban-form-btn {
  display: flex;
  gap: 10px;
  justify-content: end;
  align-items: center;
  padding: 5px 0px;
}

.status-form {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  margin-top: 5px;
}

.border-line {
  border-bottom: 1px solid #eeeeee;
}

.form-container-style {
  display: flex;
  align-items: center;
  gap: 5px;
}

//Bankruptcy Card

.bankruptcy-card {
  margin-top: 10px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 10px;
}

.bankruptcy-conatainer {
  position: absolute;
  top: 1%;
  right: 5%;
}

.bank-card-name {
  display: flex;
  gap: 5px;
  align-items: center;
}

.bank-icon-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

//Bankruptcy Manitenance

.bankruptcy-main-container {
  width: 100%;
  display: flex;
}

.add-bankruptcy-form {
  position: absolute;
  top: 3%;
  right: 2%;
}

.bank-table-popover {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.bank-table-popover:hover {
  background-color: #f8f8fb;
}

.bank-ssn-details {
  padding: 10px;
  background-color: #fafafa;
}

.bankruptcy_audit {
  border-bottom: 1px solid #cdced9;
  padding-bottom: 5px;
}

.attorney-card-conatainer {
  padding-left: 115px;
}

.add-attorney-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10px;
  cursor: pointer;
}

.bank-save-btn {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}

.form-ph-sec {
  display: flex;
  gap: 5px;
}

.bank-card-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counsel-card {
  display: flex;
  gap: 5px;
  align-items: center;
}

.counsel-card-details {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.choose-card-contain {
  display: flex;
  align-items: center;
}

.choose-card-contain-sec {
  width: 144px;
  margin-bottom: 10px;
}

.choose-typo {
  color: #adadad;
  font-family: 400;
}

.border-line-contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px;
}

.border-line-contact {
  border-bottom: 1px solid #cdced9;
  width: 100px;
}