.ctp-container {
  padding: 0px 25px;
  padding-bottom: 25px;
  background-color: #ffffff;
  height: 85vh;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid #cdced9;
}

.quill-bolt {
  padding: 5px;
  border-radius: 3px;
  background-color: paleturquoise;
}

.--cus__pro {
  padding: 0px 0px;
}

.ctp-container__header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.--cus__pro__head {
  padding: 20px 30px;
  background-color: #f8f8fb;
  margin-bottom: 15px;
}

.ctp-container__body {
  display: flex;
  height: 85%;
  gap: 15px;
}

.ctp-header__title {
  display: flex;
  align-items: center;
}

.ctp-header__title-text {
  margin-left: 12px;
  margin-right: 15px;
  padding-bottom: 0px;
}

.ctp-language {
  display: flex;
  align-items: center;
  gap: 30px;
}

.ctp-variables-tab {
  height: "100%";
  box-shadow: 1px 2px 4px 0 #33386025;
  border-radius: 5px;
  min-width: 391px;
}

.functional-card p {
  color: #adadad;
}

.ctp-variables__overflow {
  overflow: scroll;
  height: 200px;
  // height: 90%;
}

.ctp-container-bottom__button {
  width: 100%;
  height: 65px;
  background-color: rgba(238, 238, 238, 0.208);
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 1px solid rgb(205, 206, 217);
  border-right: 1px solid rgb(205, 206, 217);
  border-left: 1px solid rgb(205, 206, 217);
  padding-right: 15px;
}

.ctp-container-bottom__button div {
  width: 126px;
}

.ctp-content__text-area {
  width: 100%;
  height: 100%;
  box-shadow: rgb(51 56 96 / 14%) 1px 2px 4px 0px;
  border-radius: 5px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  border-radius: 5px 5px 0px 0px;
  border-bottom: none;
}

.ctp-card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ctp-card-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.ctp-header__action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tem-chip-alain {
  color: #516beb;
  margin-left: 8px;
  background-color: #516beb15;
  padding: 0px 5px;
  border-radius: 5px;
}

.ctp-card-bottom {
  display: flex;
  justify-content: space-between;
}

.info-styles {
  display: flex;
  align-items: center;
}

// .ctp-card .ctp-header__action svg path {
//   fill: #fff;
// }
.ctp-card .ctp-header__action {
  display: none;
}

.ctp-card:hover .ctp-header__action {
  display: block;
}

.ctp-card:hover .ctp-header__action svg path {
  fill: #adadad;
}

.ctp-card:hover .ctp-card__premium {
  display: none;
}

.ctp-card__premium {
  padding: 5px;
}

.ctp-toggle__icon {
  display: flex;
  align-items: center;
}

.ctp-card .ctp-card__premium svg {
  height: 24px;
  width: 24px;
}

.tem-content-modifier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.modifive-text {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.modifive-input {
  width: 180px;
}

.template-layout {
  // background-color: #ffffff;
  // border-radius: 5px;
  // display: flex;
  overflow-x: hidden;
  // overflow-y: scroll;
  // width: 100%;
  height: 87%;
  // flex-wrap: wrap;
  // gap: 32px;
}

.ctp-discription {
  width: 300px;
  padding-right: 20px;
  flex: 1;
}

.ctp-discription p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // word-break: break-all;
}

.timeline_body_elips {
  // width: 300px;
  padding-right: 20px;
  flex: 1;
}

.timeline_body_elips p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ctp-header__action {
  display: none;
}

.ctp-card {
  width: 315px;
  height: 220px;
  background-color: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 10px 16px 16px 16px;
}

.ctp-card:hover {
  box-shadow: var(--elevation-1);
}

.ctp-card--new {
  background-color: #e7ebee;
  cursor: pointer;
}

.ctp-active {
  font-weight: 700;
  color: #516beb;
}

.ctp-container__textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: #33386024 1px 2px 4px 0px;
  border-radius: 5px;
}

//Editor Variables
.template-marker {
  background-color: lighten($color: $primary, $amount: 30);
  color: var(--primary);
  border-radius: 5px;
  padding: 5px;
}

.sms-text-box-btn {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: rgb(249, 249, 249);
  border-left: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
  border-radius: 0px 0px 5px 5px;
}
.filedesktop:hover {
  background-color: #f0f5ff;
  border-radius: 5px;
}
.filedesktop {
  padding: 2px 20px 2px 9px;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 0.5px solid #eee;
}
.emai_file_upload_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  // padding: 20px 10px;
  // margin-bottom: 15px;
  // height: 101px;
}
.emai_file_upload_card-count {
  background-color: #ffffff;
  text-align: center;
  border-radius: 5px;
  // padding: 0px 6px 0px 6px;
  width: 26px;
  padding: 8px 0px;
  // height: 34px;
  border: 1px solid #c7c7c7;
}
.email_file_upload_icon_name {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 120px;
}

.email_file_upload_icon_name svg {
  // height: 30px;
  width: 30px;
}
// .sms-text-box-btno {
//   display: flex;
//   justify-content: s;
//   padding: 15px;
//   background: rgb(249, 249, 249);
//   border-left: 1px solid #cdced9;
//   border-bottom: 1px solid #cdced9;
//   border-right: 1px solid #cdced9;
//   border-radius: 0px 0px 5px 5px;
// }

.quill {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.communaction_tem .ql-container {
  height: 100% !important;
  overflow: scroll;
  flex: 1 1 1px;
}

.communaction_tem .tox .tox-editor-header {
  z-index: 0 !important;
}

.whatsapp_quill .ql-container {
  height: 200px !important;
  overflow: scroll;
}

// .whatsapp_quill #editor {
//   height: 200px;
// }

.ctp-name__wraper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ctp-name__wraper div {
  white-space: nowrap;
}

.ctp-card .ctp-name__wraper div {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctp-card:hover .ctp-name__wraper div {
  white-space: nowrap;
  max-width: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctp-toggle__icon__wraper {
  display: flex;
  align-items: center;
}

.ctp-disable__icon {
  display: none;
}

.ctp-premium__enable {
  display: none;
}

// disable style

.disable-card__layout {
  width: 315px;
  height: 220px;
  background-color: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 10px 16px 16px 16px;
}

.disable-card__layout .ctp-header__action {
  display: none;
}

.disable-card__layout:hover .ctp-header__action {
  display: block;
}

.disable-card__layout .ctp-name__wraper div {
  color: #adadad;
}

.disable-card__layout .ctp-name__wraper div {
  color: #adadad;
}

.disable-card__layout .ctp-discription--clamp p {
  color: #adadad;
}

.disable-card__layout .wf-discription__action-made div {
  color: #adadad;
}

.disable-card__layout .wf-discription__action-made span {
  color: #adadad !important;
}

.disable-card__layout .wf-action__ali div {
  color: #adadad;
}

.disable-card__layout .wf-action__ali svg path {
  fill: #adadad;
}

.disable-card__layout .ctp-card-bottom .info-styles div {
  color: #adadad;
}

.disable-card__layout .ctp-name__wraper div {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-card__layout:hover .ctp-name__wraper div {
  white-space: nowrap;
  max-width: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// disabled for communication template

.disable-card__layout .ctp-card-container__header svg path {
  fill: #adadad;
}

.ct-card__title-waper {
  display: flex;
  margin-bottom: 5px;
  gap: 5px;
  align-content: center;
}

.disable-card__layout .ct-card__title-waper div {
  color: #adadad;
}

.disable-card__layout .ct-card__title-waper p {
  color: #adadad !important;
}

.disable-card__layout .ctp-discription p {
  color: #adadad !important;
}

.disable-card__layout .ct-card__bottom-section div {
  color: #adadad !important;
}

.disable-card__layout .ct-card__bottom-section span {
  color: #adadad !important;
}

.disable-card__layout .ctp-card-bottom .info-styles svg path {
  fill: #adadad;
}

.filter__select {
  margin-top: 9px;
}

.react-quill-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter__select svg path {
  fill: #adadad;
}

.cmt-tag {
  border: 1px solid #ff9a76;
  color: #ff9a76;
  padding: 5px;
  border-radius: 5px;
}

.cmt-tag p {
  color: #ff9a76;
}

.--linked__invoice {
  flex: 1 !important;
}

.communaction_tem {
  flex: 1;
}

.--quill_scroll {
  overflow: hidden;
}

.--whatsapp_hover {
  cursor: pointer;
}

.--timeline_bg {
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.--timeline_chat_body {
  height: 100%;
  padding-bottom: 0px;
  // display: flex;
  // flex-direction: column-reverse;
}

.--timeline_chat_box {
  margin-bottom: 10px;
  float: left;
}

.--timeline_chat_box_right {
  margin-bottom: 10px;
  float: right;
  margin-right: 10px;
  background-color: #d9fdd3;
}

.--timeline_chat_box_right::before {
  border: 0px solid transparent;
  border-radius: 0.25rem;
  border-top: 15px solid #fff;
  content: "";
  height: 0;
  left: 100%;
  position: absolute;
  top: 0px;
  width: 0;
}

.--timeline_chat_box_right::after {
  border: 10px solid transparent;
  border-bottom: 9px solid #d9fdd3;
  content: "";
  height: 0;
  left: 96%;
  position: absolute;
  top: -10px;
  width: 0;
  border-radius: 10px;
}

.--timeline_chat_box_left {
  float: left;
  margin-bottom: 10px;
}

.timeline_whatsapp_url {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 7px;
  background-color: #d9fdd3;
  border-top: 1px solid #e5e7eb;
  height: auto;
  width: 260px;
  margin-right: 10px;
  color: #516beb;
  border-radius: 0px 0px 5px 5px;
  // margin-top: -6px;
}

.timeline_whatsapp_quick_text {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 7px;
  margin-top: 4px;
  background-color: #d9fdd3;
  height: auto;
  width: 260px;
  margin-right: 10px;
  color: #516beb;
  border-radius: 5px 5px 5px 5px;
}

.--timeline_chat_now_avatar {
  justify-content: space-between;
}


.template_email_attachment{
  height: 55px;
  overflow: hidden;
}

.letter_tem--svg{
  display: flex;
  gap: 5px;
}
.letter_tem--svg svg{
  transform: rotateZ(90deg);
  margin-top: 5px;
}