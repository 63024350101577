.view-picker {
  background-color: #ffffff;
border-radius: 5px;
}

.view-picker__save-view-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  color: var(--primary);
  height: 30px;
  border: 1px solid var(--border);
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.view-picker__title {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
