.wrapper {
  margin-top: 14px;
  position: relative;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-four {
  display: flex;
  justify-content: center;
}
.cir {
  height: 20px;
  width: 20px;
  background-color: #f5f6fa;
  border-radius: 50%;
  /* display: inline-block; */
}
.cirnum-one {
  font-size: 12px;
  text-align: center;

  /* position: absolute; */
  padding-top: 5px;
  /* z-index: 9999; */
  /* bottom: 4px; */
  /* left: 4px; */
}
.cirnum-two {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  /* position: absolute; */
  /* z-index: 9999; */
  /* bottom: 4px; */
  /* left: 81%; */
}
.line {
  position: absolute;
  border-top: 5px solid #f5f6fa;

  width: 27px;
  left: 29%;
  top: 36%;
}
.numone {
  position: absolute;
  font-size: 8px;
  /* z-index: 9999; */
}
.numtwo {
  font-size: 8px;
  color: "#666666";
  position: absolute;
  /* padding: 3px; */
  /* z-index: 9999; */
}
/* .cir {
  height: 17px;
  width: 17px;
  background-color: #f5f6fa;
  border-radius: 50%;
  display: inline-block;
} */
.cirnum-four {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;

  /* position: absolute; */
  /* z-index: 9999; */
  /* bottom: 4px; */
  /* left: 27px; */
}
.cirnum {
  height: 20px;
  width: 20px;
  background-color: #f5f6fa;
  border-radius: 50%;
  /* display: inline-block; */
}
.add-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
