$primary: #516beb;
$secondary: #fcc312;
$sidebarbg: #2a3042;
// $surface: #f0f3f8;
$surface: #f8f9fa;
$background: #eeeeee;
$border: #cdced9;
$table-background-color: #f5f6fa;
$white: #ffffff;
$text-color: #333860;
$drag-drop-color: #d6d6d6;
$elevation-1: 1px 2px 4px 0px #33386025;
$elevation-2: 0px 4px 4px 0px #33386025;
$elevation-3: 3px 5px 5px 0px #33386025;
$expand-row-color: #f3f3f3;
$expand-row-bottom: #fbfbfb;
$expand-text-color-bottom: #e1563c;

//MX V2 Variables
$text: #333860;
$text-secondary: #787878;
$border: #cdced9;
$seperator: #787878;
$error: #fd372a;
$success: #0fb158;
$info: #fcc312;
$text-white: #ffffff;

// $page:{primary_color}
:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --surface: #{$surface};
  --background: #{$background};
  --sidebarbg: #{$sidebarbg};
  --border: #{$border};
  --table-background-color: #{$table-background-color};
  --white: #{$white};
  --text-color: #{$text-color};
  --drag-drop-color: #{$drag-drop-color};
  --elevation-1: #{$elevation-1};
  --elevation-2: #{$elevation-2};
  --elevation-3: #{$elevation-3};
  --expand-row: #{$expand-row-color};
  --expand-row-bottom: #{$expand-row-bottom};
  --expand-text-color-bottom: #{$expand-text-color-bottom};

  // for tost var
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 255px !important;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 50px !important;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

:export {
  PRIMARY_COLOR: $primary;
  SECONDARY_COLOR: $secondary;
  SURFACE_COLOR: $surface;
  BACKGROUND_COLOR: $background;
  HOVER_COLOR: $sidebarbg;
}
