.whatsapp_header-part {
  background-color: #f1f2f6;
  border-radius: 9px 9px 0px 0px;

  display: flex;
  align-items: center;
  padding: 9px 6px;
  height: 53px;
}

.whatsappPreview_chat-box {
  background-color: #fff;
  height: auto;
  width: 260px;
  position: relative;
  margin-left: 4%;
  // border-radius: 0 3px 0px 0px;
  border-radius: 5px 5px;
  padding: 2px 0.5rem 3px;
}

.quick.whatsappPreview_chat-box {
  border-radius: 5px 5px;
}

.whatsappPreview_chat-box::before {
  border: 10px solid transparent;
  border-radius: 0.25rem;
  border-top: 15px solid #fff;
  content: "";
  height: 0;
  left: -10px;
  position: absolute;
  top: 0px;
  width: 0;
}

.whatsapp_mainSheet {
  background-image: url(../img/whatsapp_Textbackground.png);
  height: 550px;
  overflow: scroll;
  background-size: 100% 100%;
  padding-bottom: 20%;
}

.whatsapp_time {
  position: relative;
  padding-bottom: 15px;
}

.whatsappPreview_chat-box h6 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  line-height: normal;
}

.whatsapp_time h6 {
  text-align: end;
  position: absolute;
  right: -0px;
  top: -1px;
}

.whatsapp_profile {
  display: flex;
  gap: 9px;
  margin: 0;
  align-items: center;
  margin-left: 9px;
}

.whatsapp_contact-us {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 7px;
  color: #516beb;
  background-color: #fff;
  border-top: 1px solid #e5e7eb;
  height: auto;
  width: 260px;
  margin-left: 4%;
  cursor: pointer;
}

.whatsapp_website_url {
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 7px;
  background-color: #fff;
  border-top: 1px solid #e5e7eb;
  height: auto;
  width: 260px;
  margin-left: 4%;
  color: #516beb;
  border-radius: 0px 0px 5px 5px;
}

.whatsapp_Quick {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 7px;
  margin-top: 4px;
  background-color: #fff;
  // border-top: 1px solid #e5e7eb;
  height: auto;
  width: 260px;
  margin-left: 4%;
  color: #516beb;
  border-radius: 5px 5px 5px 5px;
}

// .whatsapp_footer-part{
//   position: relative;
//   height: 250px;
//   width: 100%;
// }
.whatsapp_footer {
  background-color: #f1f2f6;
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 5px 5px;
  bottom: 0;
  position: absolute;
  border-radius: 0 0 23px 23px;

  .whatsapp_Textbox {
    width: 60%;
    border-radius: 5px;
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
    align-items: center;
    display: flex;
    padding: 0px 9px;
    height: 80%;
    background-color: #fff;
  }
}

.attachment_fill {
  transform: translateX(-4px);
}

.whatsapp_footer-text {
  height: 26px;
  width: 161px;
  border-radius: 5px;
  transform: translateX(-4px);
}

.whatsapp_phone-background {
  background-image: url(../img/phonescreen.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  max-width: 914px;
  position: relative;
  height: 900px;
}

.whatsapp_profile_pic {
  padding: 0px 1px;
  align-items: center;
  display: flex;
}

.wh_profile {
  height: 20% !important;
}

.whatsapp_background {
  position: absolute;
  right: 90px;
  left: 0px;
  top: 31px;
  width: 100%;
}

.whatsapp_footer-text::placeholder {
  text-align: center;
}

.iphone {
  position: relative;
  overflow: hidden;
  margin: 30px auto;
  width: 307px;
  height: 652px;
  background-color: #f1f2f6;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 0px #111;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // home button indicator
  &:after {
    bottom: 6px;
    width: 58%;
    height: 4px;
    background-color: #000000;
    border-radius: 10px;
  }

  // frontal camera/speaker frame
  &:before {
    // top: 0px;
    // width: 55%;
    // height: 30px;
    // background-color: #1f1f1f;
    // border-radius: 0px 0px 40px 40px;
    top: 0px;
    width: 47%;
    height: 23px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 19px 19px;
  }

  i,
  b,
  s,
  span {
    position: absolute;
    display: block;
    color: transparent;
  }

  // speaker
  i {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 13%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }

  // camera
  b {
    left: 5%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);

    &:after {
      content: "";
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    }
  }

  // time
  s {
    color: #000;
    // text-align: center;
    position: absolute;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    left: 35px;
    top: 7px;
  }

  // action buttons
  span {
    bottom: 50px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    left: 30px;

    & + span {
      left: auto;
      right: 30px;
    }
  }
}

.--whatsapp_msg {
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
}

.-notification {
  width: calc(100% - 501px);
  margin-right: 2%;
  background-color: #d9fdd3;
}

.--notification_active svg path {
  fill: #ffffff;
}
