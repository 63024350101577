.container-over {
  display: flex;
  /* width: calc(100% - 60px); */
  /* width: 1000px; */
  height: 86vh;
  flex-direction: column;
  padding: 0px 20px;
  /* background-color: #adadad; */
}
.header-main {
  display: flex;
}
.header {
  padding-left: 15px;
}

/* .card-over { */
  /* overflow: scroll; */
  /* border: 1px solid black; */
  /* margin-top: 0px; */
/* } */

/* .combine-container { */
  /* display: flex; */
  /* padding-top: 34px; */
  /* overflow: hidden; */
  /* position: absolute; */
/* } */
.sections {
  display: flex;
  justify-content: space-between;
}
.sections-title {
  font-size: 16px;
  color: #adadad;
  /* font-weight: bold; */
}
.sections-value {
  padding-top: 5px;
  font-weight: bold;
  color: "#333860";
}
.img {
  text-align: center;
  padding-top: 20px;
}
.img-content-one {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.img-content-two {
  color: #adadad;
  font-size: 16px;
  padding-top: 16px;
  text-align: center;
}
.img-content-three {
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
}

.double-section {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-bottom: 0.5px dashed #cdced9;
  border-spacing: 15px;
}
.agentinst_popup{
   padding: 4px 20px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 6px;
border-bottom:1px solid #EEEE ;
}
.agentinst_condensedpopup{
   padding: 4px 20px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 6px;
}
.agentinst_header{
  padding: 10px 20px;

}
.agentInstruction_footer{
  display: flex;
  justify-content: center;
   padding: 10px 20px 25px;
}
.alret_bordertop{
   border-top: 4px solid #FD372A;
   border-radius: 5px 5px 0px 0px;
}
.alret_borderpopup{
  margin: 10px 0px;
   border-top: 1px solid #EEE;
}
.agentinst_condensed{
  padding: 25px 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agentunst_title{
  display: flex;
  align-items: center;
  justify-content: left;
}
.agentinst_popup-dot{
  width: 6px;
  height: 6px;
  background-color: #adadad;
  border-radius: 50%;
}
.alret_popup{
  display: flex;
  align-items: center;
  gap: 8px;
}
.--requestactive {
  color: #516beb !important;
  border-radius: 5px;
  border: 1px solid #516beb !important;
  background: none;
  padding: 0px 10px;
  width: auto;
}
.--requestactive:hover {
  background: none;
  border: 1px solid #516beb;
  color: #fff;
}
.request_category {
  color: #333860;
  border-radius: 5px;
  border: 1px solid #cdced9;
  background: none;
  padding: 0px 10px;
  width: auto;
}
.request_category:hover {
  background: none;
  border: 1px solid #516beb;
  color: #516beb;
}
.category_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.request_information_card {
  border: 1px solid #cdced9;
  border-radius: 9px;
  overflow: hidden;
  margin-top: 10px;
}
.request_edit_pencil {
  display: none;
}
.request_information_card:hover .request_edit_pencil {
  display: block !important;
}
.count_inv {
  border-radius: 5px;
  background: #f0f5ff;
  color: #516beb;
  padding: 2px 3px;
}
.count_notification {
  border-radius: 5px;
  color: #516beb;
  padding: 2px 3px;
}
.request_flex {
  display: flex;
  justify-content: end;
  gap: 5px;
  padding: 0px 0px 7px 0px;
}
.request_header {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}
.request_head_line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.request_doc_card {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: 1px solid #cdced9;
  padding: 4px 8px;
  border-radius: 6px;
}
.request_dot {
  width: 5px;
  height: 5px;
  background: #333860;
  border-radius: 5px;
}
.request_dot_clarifi {
  width: 5px;
  height: 5px;
  background: #adadad;
  border-radius: 5px;
}
.review_date {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: end;
}
.clarification_form {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* padding: 15px 0px 0px; */
}
.request_label {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* padding: 10px 0px; */
}

.request_status {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}
.request_top_border {
  border-top: 1px solid #adadad;
  width: 100%;
}
.disputer_form {
  width: 85%;
  display: flex;
  align-items: center;
  gap: 15px;
}
.viewmore_btn {
  padding: 4px;
  gap: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewmore_icon {
  margin-top: 3px;
  transform: rotate(180deg);
}
.dispute_time_line_status_inprogress {
  color: #fbbd04 !important;
  font-size: 16px;
}
.dispute_time_line_status_open {
  color: #fd372a !important;
  font-size: 16px;
}
.dispute_time_line_status_close {
  color: #48b24f !important;
  font-size: 16px;
}
.dispute_timeexpend_card {
  width: 80px;
}
.dot_dipute {
  width: 5px;
  height: 5px;
  /* background-color: #FBBD04; */
  border-radius: 5px;
}
.dispute_captured_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0px 0px 5px 4px;
  border-top: 1px solid #cdced9;
  background: rgba(250, 250, 250, 0.85);
  padding: 5px 0px;
}
.footer_replay-btn {
  position: absolute;
  right: 0px;
}
.--dispute_header {
  padding: 10px 25px 10px 25px;
}

.clarification-category {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.clarification-category-button {
  border: 1px solid #cec3ce;
  padding: 3px 6px;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
}

.clarification-category-button--selected {
  border: 1px solid #516beb;
  color: #516beb;
}
