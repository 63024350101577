.scroll-icn {
  height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* margin-right: -4px; */
  margin: 0px 10px;
}

.nested {
  background-color: #ffffff;
  height: 296px;
  padding: 21px 14px;
}

.nes-view-two {
  padding: 23px 14px;
  background-color: #ffffff;
}

.nested-view-two-text {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.nested-view-parent-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 36px;
}

.nested-view-input-one {
  width: 100%;
  height: 40px;
  padding-bottom: 16px;
}

.nested-view-input-two {
  width: 100%;
  height: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nested-view-enable-one {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  /* padding-top: 35px; */
}

.nested-view-enable-two {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 16px; */
  /* padding-top: 15px; */
}

.nested-view-btn-save {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.nested-view-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 16px;
}

.nested-view-content {
  height: 40px;
  color: #666666;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding: 10px;
}

.nested-view-content-text {
  font-weight: 500;
  /* padding-left: 15px; */
}

.nested-view__default-ellipsis {
  display: flex;
}

.nested-text__ellipsis {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
}

.nested-view-two {
  padding-bottom: 16px;
}

.nested-view__delete-btn {
  display: flex;
}

.nested-view__mark-default,
.nested-view__name-el {
  display: none;
}

.nested-view-content .nested-view__mark-default {
  color: #516beb;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0%;
  background-color: #ffffff;
}

.view-container {
  height: 26px;
  color: #666666;
  display: flex;
  align-items: center;
  position: relative;
  /* border-bottom: 1px solid #eeeeee; */
  /* padding: 10px; */
  border-radius: 5px;
}

.view-container:hover {
  background-color: #e9efff;
  color: #516beb;
}

.view-container:hover .pinned-icon path {
  fill: #516beb !important;
}

.view_actions {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0%;
  background-color: #e9efff;
}

.view-container:hover .view_actions {
  display: flex;
}

.view__action-button {
  padding: 5px;
  cursor: pointer;
}

.view__action-button svg path {
  fill: var(--text-color);
}

.view__action-button--delete svg path {
  fill: #fd372a;
}

.view__action-button:hover {
  background-color: #eeeeee50;
  border-radius: 5px;
}

.view_default-chip {
  background-color: #f2f5ff;
  padding: 0px 5px;
  border-radius: 5px;
  color: var(--primary);
}

.view__mark-as-default {
  padding: 3px;
  color: var(--primary);
  cursor: pointer;
}

.view__mark-as-default:hover {
  background-color: #eeeeee50;
  border-radius: 5px;
}

.view-pinned {
  padding: 5px 8px;
  border: 1px solid #cdced9;
  font-size: 14px;
  color: var(--text-color);
  border-radius: 5px;
  /* height: 30px; */
  cursor: pointer;
}

.view-pinned--selected {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.view-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
