.paymentplan_view-btn {
  width: calc(100% - 110px);
}

.--mainpaymentplans {
  width: 100%;
  gap: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.--c3_paymentBox {
  width: 19%;
}

.--changed {
  width: 37.9%;
}

.--drawer_payment_amount_changes {
  width: 50%;
  // border: 1px solid #ADADAD;
}

.monthly_plan {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
  align-items: center;
}

.paymentplan_input {
  background-color: #ffffff;
  width: 750px;
  flex: 0.5;
  border-radius: 5px;
}

.isuparrow {
  // width: 30px;
  // border: 1px solid #000;
  transform: rotate(180deg);
}

// .monthlyupdate {
//   // width: 100%;
//   // border: 1px solid #000;
// }

//Call Payment
.call_payment {
  display: flex;
  align-items: center;
  gap: 4px;
}
.req_info {
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.dot-open {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #FD372A;
}
.-paymentCall{
  color:  #333860;
//   font-family: Roboto;
font-size: 16px;
// font-style: normal;
font-weight: bold;
// line-height: normal;
}
.Normalize{
  color:  #333860;
font-size: 16px;
}

.Successed{
  color: #0FB158;
  font-size:16px
}
.payment_plan_header{
  padding-left: 12px;
}