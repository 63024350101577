@font-face {
  font-family: "mxicons";
  src: url("../FavIcon/mxicons.eot?x8j9wt");
  src: url("../FavIcon/mxicons.eot?x8j9wt#iefix") format("embedded-opentype"),
    url("../FavIcon/mxicons.ttf?x8j9wt") format("truetype"),
    url("../FavIcon/mxicons.woff?x8j9wt") format("woff"),
    url("../FavIcon/mxicons.svg?x8j9wt#mxicons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "mxicons";
  src: url("../FavIcon/mxicons.eot?ug9tn1");
  src: url("../FavIcon/mxicons.eot?ug9tn1#iefix") format("embedded-opentype"),
    url("../FavIcon/mxicons.ttf?ug9tn1") format("truetype"),
    url("../FavIcon/mxicons.woff?ug9tn1") format("woff"),
    url("../FavIcon/mxicons.svg?ug9tn1#mxicons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "mxicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mx-icon-Field-visit:before {
  content: "\e937";
  color: #516beb;
}
.mx-icon-email-reply:before {
  content: "\e938";
  color: #516beb;
}
.mx-icon-mention:before {
  content: "\e939";
  color: #516beb;
}
.mx-icon-view-less:before {
  content: "\e92b";
  color: #adadad;
}
.mx-icon-download:before {
  content: "\e92a";
  color: #20c58a;
}
.mx-icon-call-back-2:before {
  content: "\e929";
  color: #08083a;
}
.mx-icon-document-1 .path1:before {
  content: "\e913";
  color: rgb(51, 56, 96);
}
.mx-icon-document-1 .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.mx-icon-document-1 .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.mx-icon-document-1 .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.mx-icon-document-1 .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(51, 56, 96);
}
.mx-icon-document-1 .path6:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(51, 56, 96);
}
.mx-icon-diary-note .path1:before {
  content: "\e919";
  color: rgb(51, 56, 96);
}
.mx-icon-diary-note .path2:before {
  content: "\e91a";
  margin-left: -0.7822265625em;
  color: rgb(255, 255, 255);
}
.mx-icon-diary-note .path3:before {
  content: "\e91b";
  margin-left: -0.7822265625em;
  color: rgb(255, 255, 255);
}
.mx-icon-diary-note .path4:before {
  content: "\e91c";
  margin-left: -0.7822265625em;
  color: rgb(255, 255, 255);
}
.mx-icon-diary-note .path5:before {
  content: "\e91d";
  margin-left: -0.7822265625em;
  color: rgb(51, 56, 96);
}
.mx-icon-diary-note .path6:before {
  content: "\e91e";
  margin-left: -0.7822265625em;
  color: rgb(51, 56, 96);
}
.mx-icon-calendar:before {
  content: "\e911";
  color: #333860;
}
.mx-icon-letter-status-2:before {
  content: "\e936";
  color: #516beb;
}
.mx-icon-whatsApp-16 .path1:before {
  content: "\e932";
  color: rgb(76, 175, 80);
}
.mx-icon-whatsApp-16 .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.mx-icon-left-path:before {
  content: "\e90d";
  color: #757575;
}
.mx-icon-right-path:before {
  content: "\e90e";
  color: #757575;
}
.mx-icon-info:before {
  content: "\e90f";
  color: #adadad;
}
.mx-icon-Vector-7:before {
  content: "\e90a";
  color: #333860;
}
.mx-icon-filter_list:before {
  content: "\e90b";
}
.mx-icon-unview:before {
  content: "\e934";
}
.mx-icon-view:before {
  content: "\e935";
}
.mx-icon-settings-2:before {
  content: "\e912";
}
.mx-icon-delete_FILL0_wght400_GRAD0_opsz48-2:before {
  content: "\e90c";
}
.mx-icon-arrow_drop_down_FILL0_wght400_GRAD0_opsz48-2:before {
  content: "\e907";
}
.mx-icon-arrow_drop_up_FILL0_wght400_GRAD0_opsz48-1:before {
  content: "\e909";
}
.mx-icon-manage-payment:before {
  content: "\e910";
}
.mx-icon-bank-icon:before {
  content: "\e900";
}
.mx-icon-upload:before {
  content: "\e901";
  color: #333860;
}
.mx-icon-add:before {
  content: "\e902";
  color: #333860;
}
.mx-icon-assign:before {
  content: "\e903";
  color: #fff;
}
.mx-icon-down-arrow:before {
  content: "\e904";
  color: #fff;
}
.mx-icon-checkbox-select:before {
  content: "\e92c";
  color: #fb0;
}
.mx-icon-check-box-unselect:before {
  content: "\e92d";
  color: #adadad;
}
.mx-icon-view-less1:before {
  content: "\e92e";
  color: #fb0;
}
.mx-icon-expand_more:before {
  content: "\e92f";
  color: #fb0;
}
.mx-icon-expand_more_FILL0_wght400_GRAD0_opsz48-9-2:before {
  content: "\e930";
  color: #fb0;
}
.mx-icon-expand_more_FILL0_wght400_GRAD0_opsz48-9-1:before {
  content: "\e931";
  color: #fb0;
}
.mx-icon-Group-14:before {
  content: "\e91f";
  color: #787878;
}
.mx-icon-Group-16179:before {
  content: "\e920";
  color: #1a73e8;
}
.mx-icon-Group-16230:before {
  content: "\e921";
  color: #1a73e8;
}
.mx-icon-Group-16860:before {
  content: "\e922";
  color: #1a73e8;
}
.mx-icon-Group-16969:before {
  content: "\e923";
  color: #08083a;
}
.mx-icon-Vector:before {
  content: "\e924";
  color: #1a73e8;
}
.mx-icon-Vector-1:before {
  content: "\e925";
  color: #adadad;
}
.mx-icon-Vector-2:before {
  content: "\e926";
  color: #08083a;
}
.mx-icon-Vector-3:before {
  content: "\e927";
  color: #08083a;
}
.mx-icon-Vector-4:before {
  content: "\e928";
  color: #08083a;
}
.mx-icon-clear:before {
  content: "\e905";
  color: #adadad;
}
.mx-icon-drop-down:before {
  content: "\e906";
  color: #adadad;
}
.mx-icon-Enable-Disable:before {
  content: "\1f350";
  color: #516beb;
}
.mx-icon-sort:before {
  content: "\e908";
}
