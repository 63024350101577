.list-council-card-container {
  display: flex;
  gap: 14px;
  padding: 10px;
  height: 100px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  background-color: #fafafa;
}

.list-council-card-subContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.list-council-card-select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}

.list-council-card_select {
  background-color: #ffff;
  width: 175px;
  height: 28px;
  border: 1px solid #cdced9;
  border-radius: 5px;
}
.list-report-main_select {
  background-color: #ffff;
  width: 248px;
  height: 28px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-council-card_select {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-council-card-add_button {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

//ListCouncel-container
.list-councel-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  background-color: #ffff;
}
.list-councel-allow-accses-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 180px;
  padding: 5px;
  min-height: 100px;
  max-height: 165px;
  overflow: scroll;
}
.list-report-maintainance-accses-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 250px;
  padding: 10px;
}
.councel_selection_list {
  padding: 8px;
}
.concel_select_conuncel-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 345px;
  height: 145px;
  padding: 10px;
}

.concel_selection_list_select {
  height: 98px;
  overflow: scroll;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.report-maintainanace_list_select {
  max-height: 165px;
  overflow: scroll;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.councel_selection_list:hover {
  cursor: pointer;
  background-color: #f0f5ff;
}

//counsel Form

.counsel-form-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  background-color: #ffff;
}

//added-counsel-card-container

.added-counsel-card-container {
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.added-counsel-card_header {
  display: flex;
  justify-content: space-between;
}

//more-Action
.legal-moreAction-popper-container {
  display: flex;
  cursor: pointer;
  // align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  // border: 1px solid #cdced9;
  // gap: 5px;

  .regular:hover {
    background-color: #f0f5ff;
    color: #516beb;
  }
}

.legal-info-poper {
  display: flex;
  // padding: 5px;//
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  // border: 1px solid #cdced9;
  // gap: 7px;
  .regular:hover {
    background-color: #f0f5ff;
    color: #516beb;
  }
}
