.Toastify__toast {
    position: relative;
        min-height: var(--toastify-toast-min-height) !important;
        box-sizing: border-box;
        margin-bottom: 1rem;
        padding: 3px 8px !important;
        border-radius: 4px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: space-between;
        max-height: var(--toastify-toast-max-height);
        overflow: hidden;
        font-family: var(--toastify-font-family);
        cursor: pointer;
        direction: ltr;
}
.Toastify__close-button {
    display: none !important;
}