//search conatainer
.search-user-main-container {
  width: 336px;
  height: 430px;
  display: flex;
  position: sticky;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #cdced9;
  background-color: #fff;
}
.user-line {
  position: relative;
  top: 16px;
  left: 28px;
  right: 18px;
  height: 1px;
  object-fit: cover;
  width: 290px;
  background: rgba(238, 238, 238, 0.93);
}

.search-user-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
  top: 15px;
}

.frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 15px;
  position: relative;
}

.group {
  height: 35px;
  position: relative;
}

.eclipse-wrapper {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  position: relative;
  left: 13px;
  fill: #f0f5ff;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
}

.userContainer {
  position: relative;
  left: 15px;
}

.text-wrapper-2 {
  color: #333860;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.date-time {
  color: transparent;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.span {
  color: #787878;
}

.text-wrapper-3 {
  color: #333860;
}

.device-type-iphone {
  align-self: stretch;
  color: transparent;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
}

.location-User-Search {
  display: flex;
  width: 325px;
  padding: 5px 10px 5px 5px;
  align-items: center;
  gap: 10px;
  position: relative;
  left: 8px;
}

.frame-wrapper {
  height: 18px;
  position: relative;
  width: 176px;
}

.div-3 {
  align-items: flex-start;
  display: inline-flex;
  gap: 2.5px;
  position: relative;
}

.text-wrapper-4 {
  color: #787878;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-wrapper {
  height: 18px;
  position: relative;
  width: 50.5px;
}
.eclipse-div {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  position: relative;
  left: 13px;
  fill: #f0f5ff;
}

.div-3 {
  align-items: flex-start;
  display: inline-flex;
}

.battery {
  width: 18px;
  height: 18px;
}

.text-wrapper-5 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

//map-container
// .map-container {
//   display: flex;
//   width: 1150px;
//   height: 435px;
//   flex-shrink: 0;
//   gap: 20px;
//   position: relative;
//   top: 21px;
// }
.main-container-select-input {
  display: flex;
  gap: 20px;
  position: relative;
  top: 16px;
}
.current-location-select-input {
  display: flex;
  flex-direction: row;
  width: 245px;
  gap: 15px;
  height: 45px;
}
.agent-activity-select-container {
  width: 350px;
  height: 45px;
  flex-shrink: 0;
  display: flex;
  gap: 20px;
}

.location-tracker-filter-container {
  display: flex;
  gap: 10px;

  & > div {
    width: 250px;
  }
}

.location-tracker-container {
  display: flex;
  gap: 20px;
}

.location-tracker-map-wrapper {
  flex: 1 1;
}

.map-container {
  height: calc(100vh - 125px);
  width: 100%;
}

.location-tracker-list-container {
  width: 300px;
  background-color: #ffffff;
  border: 1px solid $border;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 125px);
}

.location-tracker-list-filter {
  padding: 10px 10px 0px 10px;
  border-bottom: 1px solid $border;
}

.location-tracker-list-wrapper {
  flex: 1 1;
  margin: 0px 0px 0px 10px;
  overflow: scroll;
}

.location-tracker-detail-card {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid $border;
  cursor: pointer;

  &:hover {
    & > .location-tracker-count {
      background-color: $primary;

      & > p {
        color: #ffffff;
      }
    }

    & > .location-tracker-info .location-tracker-card-title {
      color: $primary;
    }
  }
}

.location-tracker-detail-card--selected {
  & > .location-tracker-count {
    background-color: $primary;

    & > p {
      color: #ffffff;
    }
  }

  & > .location-tracker-info .location-tracker-card-title {
    color: $primary;
  }
}

.location-tracker-count {
  background-color: #f0f5ff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;

  & > p {
    color: $primary;
    width: 30px;
    text-align: center;
  }
}

.location-tracker-card-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.location-tracker-card-info-label {
  font-size: 14px;
  color: #787878;
  line-height: 18px;

  & > span {
    color: $text-color;
  }
}

.location-tracker-card-battery-perc {
  display: flex;
  align-items: center;
}

.mx-marker {
  position: relative;
  width: 30px;
  height: 30px;
}

.mx-marker-text {
  position: absolute;
  top: 15%;
  width: 30px;
  text-align: center;
  left: 0%;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}

.mx-marker-circle {
  position: absolute;
  background-color: #e90e0e;
  border-radius: 50% 50% 50% 0;
  border: 4px solid #e90e0e;
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
}

.mx-marker-circle::after {
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -13px;
  margin-top: -13px;
  background-color: #ffffff;
}

.mx-marker-tooltip {
  position: absolute;
  background-color: #333333;
  min-width: 150px;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  bottom: 90%;
  left: 90%;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  color: #ffffff;
}
