.sidebar-wrapper {
  background-color: var(--sidebarbg);
  height: 100vh;
  width: 60px;

  /* width: 4rem; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sidebar-top {
  width: 60px;
  text-align: center;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-switch__container {
  position: relative;
}

.sidebar-switch__icon {
  position: absolute;
  top: 30px;
  left: 25px;
}

.sidebar-sync__cointainer {
  position: absolute;
  top: 50%;
  left: 28%;
  background-color: rgb(0, 0, 0);
  background-color: #a5a5a5d9;
  padding: 9px 9px 4px 9px;
  border-radius: 50px;
  transform: translate(-50%, -50%);
}

.sidebar-sync__cointainer svg path {
  fill: #ffffff;
}

.sidebar-sync__cointainer svg {
  /* transform: rotate(45 140 105); */
  animation: spin 3s linear infinite;
  /* transform-origin: 150px 100px; */

}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.sidebar-switch__organization-list {
  overflow: scroll;
  min-height: 70px;
  max-height: 300px;
}

.sidebar-middle {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  /* min-height: 100%; */
  /* height: 100%; */
  /* min-height: 70%; */
  /* overflow-y: scroll; */
  /* padding-top: 10px; */
  gap: 6px;
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
}

.icon-parent {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: "center";
  padding: 2px 8px;
  transition: 0.3s;
}

.multi-apotion {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: "center";
  padding: 2px 8px;
  transition: 0.3s;
}

.icon-child {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border: 1px solid #ffffff00;
}

.icon-parent:hover .icon-child {
  border: 1px solid #fff;
  border-radius: 5px;
}

.multi-apotion:hover .icon-child svg path {
  fill: #ffffff;
}
.--trip_dot {
  position: relative;
}
.drop-down {
  position: absolute;
  left: 30px;
  top: 9px;
}
.hoverclass .icon-child {
  transition: 0.3s;
  background-color: #fff;
  border-radius: 5px;
}

.hoverclass .icon-child svg path {
  transition: 0.3s;
  fill: var(--sidebarbg);
}

.sidebar-organization-list__container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0px 0px 0px;
  justify-content: space-between;
  cursor: pointer;
  flex-direction: column;
}

.sidebar-organization-list__container p {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.progress-bar__wraper {
  width: 100%;
  position: relative;
}

.progress-bar__label {
  display: flex;
  align-items: center;
  position: absolute;
  top: -6px;
  z-index: 1;
  right: 105px;
}

.progress-bar__label svg path {
  fill: #ffffff;
}

.progress-bar__label svg {
  width: 15px;
  height: 15px;
}

.--last__sync {
  left: 40px;
  width: 92%;
  gap: 3px;
}

.sidebar-organization-list__container--active {
  display: flex;
  align-items: center;
  /* gap: 10px; */
  padding: 15px 0px 0px 0px;
  justify-content: space-between;
  background-color: #f5f7ff;
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  overflow: hidden;
}

.sidebar-organization-list__container--active .avather__wraper {
  padding: 0px 15px 15px;
}

.avather__wraper {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 0px;
}

.sidebar-organization-list__container--active p {
  color: #516beb !important;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  font-weight: 700;
}

.sidebar-switch__bottom-btn {
  padding: 20px 20px 0px 20px;
  border-top: 0.5px solid #e3e3e3;
  margin-top: 10px;
}

.sidebar-switch__bottom-btn svg line {
  stroke: #ffffff;
}

.sidebar-switch__bottom-btn svg {
  margin-right: 5px;
}

.org-name {
  border-bottom: 0.5px solid #cdced9;
}

.sync__wraper {
  position: relative;
  margin-top: 10px;
}

.sync__btn-contain {
  position: absolute;
  top: 16px;
  right: 10px;
}

.--notify_wraper {
  position: relative;
}

.notifi_dot {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: #fd372a;
  top: 30%;
  right: 35%;
}

.siderbar_logo_fix {
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

@media (min-height: 300px) and (max-height: 569px) {
  .sidebar-middle {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    overflow-y: scroll;
    /* padding-top: 10px; */
    margin-top: 30px;
  }
}
