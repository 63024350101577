.ft_sz_22 {
  font-size: 18px !important;
}

.color_gr {
  color: #adadad;
}

.color_gr::before {
  color: #adadad !important;
}

.font_size_icon {
  font-size: 16px;
}

.hover_color_dlt {
  color: #adadad;
}

.payment_dlt_btn:hover .hover_color_dlt {
  color: #fd372a !important;
}

.settings_font_size {
  font-size: 16px;
}

.mx-icon-info::before {
  color: #333860 !important;
}
