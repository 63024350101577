.cwf-table__row {
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 49px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f6fa;
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 10px;
}
.cwf-table_body-row {
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 49px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 10px;
}
.cwf-table {
  overflow: scroll;
}
.cwf-table__header-dot {
  height: 7px;
  width: 100%;
  background-color: #fbbd04;
}

.cwf-table__header-dot-e2 {
  height: 7px;
  width: 100%;
  background-color: #ffc369;
}
.cwf-table__header-dot-ff {
  height: 7px;
  width: 100%;
  background-color: #ffc1bc;
}
.cwf-table__header-dot-f6 {
  height: 7px;
  width: 100%;
  background-color: #f69993;
}
.cwf-table__header-dot-ff7 {
  height: 7px;
  width: 100%;
  background-color: #ff7970;
}
.cwf-table__header-dot-fd {
  height: 7px;
  width: 100%;
  background-color: #fd372a;
}
.cwf-alert__icon svg path {
  fill: #fbbd04;
}
.cwf-table-cell__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #c3e0f3;
  padding: 5px;
  flex: 1;
}
.cwf-table-cell__content svg path {
  fill: #333860;
}
.cwf-filter__tab {
  display: flex;
  // gap: 20px;
  align-items: center;
  cursor: pointer;
}
.cwf-tab__container {
  background-color: #f5f6fa;
  border: 1px solid #cdced9;
  padding: 0px 4px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.cwf-filter__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.--cwf-header-fix {
  justify-content: left !important ;
  gap: 20px;
}
.cwf-filter__search {
  margin-top: 5px;
}
.cwf-filter__search input {
  background-color: rgb(255, 255, 255);
  width: 0;
  padding: 8px 0px 8px 0px;
  color: rgb(8, 8, 8);
  transition: all 1s;
}
.cwf-filter__search::placeholder {
  color: rgb(255, 255, 255) !important;
  font-weight: 400;
}
.cwf-filter__search:hover input {
  width: 175px;
  padding: 6px 0px 6px 6px;
}

.cwf-filter__search input:focus {
  width: 175px;
  padding: 6px 0px 6px 6px;
}
.cwf-customer-profile-tab__table {
  display: block;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 100%;
  text-align: center;
}
.cwf-adhoc-upload-tab__table {
  display: block;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
  // height: 65%;
  text-align: center;
}
.tshead {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -5px 0px 0px 0px;
}

// table style start here

.cwf-table__wrapper {
  width: 100%;
  display: table;
}
.cwf-table__header {
  display: table-caption;
  text-align: center;
  font-size: 20px;
  position: sticky;
  top: 0;
}
.cwf-table__cell-header {
  flex-direction: column;
  background-color: #f5f6fa;
  font-size: 20px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  overflow: hidden;
}
.cwf-table__header-content {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}
.cwf-table__header-cell-content {
  height: 100%;
  display: flex;
  position: relative;
  flex: 1;
}
.cwf-table__header-cell {
  position: relative;
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-left: 10px;
}
.cwf-tab__table-body {
  display: table-row-group;
}
.cwf-table__body-content {
  border: 1px solid #cdced9;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 50px;
  margin-top: 12px;
}
.cwf-table__body-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cwf-tab__filter {
  background-color: #dde3ff;
  flex: 1 1;
  padding: 0px 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cwf-chip {
  padding: 0px 2px;
  background-color: #fff;
  border: 0.5px solid #516beb;
  border-radius: 5px;
}

.cwf-chip div {
  color: #516beb;
  line-height: 17px;
}

.cwf-tab__filter p {
  color: #516beb;
  font-weight: 700;
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin-top: 3px;
}
.cwf-body__cell-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.cwf-tab__filter-clamp {
  padding: 2px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cwf-tab__filter-clamp p {
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin-top: 3px;
}
