.contact_status_type_checkbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

.contact_status_add_container{
    display: flex;
    gap: 20px;
}

.cs_field_container{
    display: flex;
    gap: 15px;
    align-items: center;
}

.cs_msg_cfi_temp{
    width: 100%;
}

.cs_msg_type{
    width: 40%;
}

.--cs_mt{
    margin-top: 0;
}

.cs_btn_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}
.cs_checkbox_container{
    margin-bottom: 15px;
}
.cs_add_field_btn_container{
    text-align: end;
}