.cmt-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.information-card-unread {
  color: #adadad !important;
}
.info-payment-success {
  height: 5px;
  width: 5px;
  background-color: #0fb158;
  border-radius: 50%;
}
.info-payment-success-read {
  height: 5px;
  width: 5px;
  background-color: #a3e4c0;
  border-radius: 50%;
}
.info-payment-failed {
  height: 5px;
  width: 5px;
  background-color: #ed1b34;
  border-radius: 50%;
}
.info-payment-failed-read {
  height: 5px;
  width: 5px;
  background-color: #e08a94;
  border-radius: 50%;
}
.info-payment-cancel {
  height: 5px;
  width: 5px;
  background-color: #f8f419;
  border-radius: 50%;
}
.info-payment-cancel-read {
  height: 5px;
  width: 5px;
  background-color: #f7f5ae;
  border-radius: 50%;
}
.info-payment-initiated {
  height: 5px;
  width: 5px;
  background-color: #adadad;
  border-radius: 50%;
}
.info-payment-initiated-read {
  height: 5px;
  width: 5px;
  background-color: #adadad;
  border-radius: 50%;
}

.information-texts {
  color: "#787878" !important;
}

.tab-isactive {
  height: 36px;
  width: 36px;
  border: 0.1px solid #cdced9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-active {
  height: 36px;
  width: 36px;
  border: 0.1px solid #516beb;
  border-radius: 5px;
  background-color: #f0f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-read svg path {
  fill: #adadad;
}
.info-unread svg path {
  fill: #516beb;
}
.sms-read svg path {
  stroke: #adadad;
}
.sms-unread svg path {
  stroke: #516beb;
}

.calendar-filter {
  background: #f0f5ff;
  border: 1.5px solid #516beb;
  color: #516beb;
  padding: 0px 5px;
  border-radius: 5px;
}
.calendar-filter-isUnActive {
  background: #ffffff;
  border: 1.5px solid #adadad;
  color: #333860;
  padding: 0px 5px;
  border-radius: 5px;
}
.calendar-filter-circle {
  height: 28px;
  width: 28px;
  border: 1.5px solid #cdced9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #333860;
}

.calendar-filter-circle-isActive {
  height: 28px;
  width: 28px;
  border: 1.5px solid #516beb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #516beb;
}
.calendar-filter-day-isActive {
  color: #516beb;
}
.calendar-card:hover .calendar-btn {
  display: block;
}
.calendar-card .calendar-btn {
  display: none;
}
