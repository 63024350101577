.sidebar-wrapper-v2 {
    background-color: var(--sidebarbg);
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    // padding: 10px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 60px;
}

.transition-wrapper {
    height: 100%;
}

.menus_container {
    height: 100%;
}

// .sidebar-top-v2 {
//     height: 100%;
// }

.siebar_top_section_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.sidebar-wrapper-v2:hover .sub-menu-indicator {
    display: none;
}

.sub-menu-indicator {
    position: absolute;
    left: 25px;
}

.--layout_modifier .sidebar-wrapper-v2 {
    height: 95vh;
}

.sidebar-top-v2 {
    text-align: center;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 10px;
}

.notification-indicator {
    position: absolute;
    top: 5px;
    left: 21px;
}

.sidebar_icon_text_container {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
}

.sidebar-wrapper-v2 h3 {
    display: none;
}

.search_active {
    width: auto !important;
    z-index: 99 !important;
}

.sidebar-wrapper-v2:hover .sidebar-sync__cointainer {
    left: 8.3% !important;
}

.search_active .sidebar-sync__cointainer {
    left: 8.3% !important;
}

.search_active h3 {
    display: inline-block !important;
    color: #ffffff;
}

.active_menu {
    background-color: #0F488C;
    border-radius: 5px;
    width: 42px;
}

.sidebar_icon_text_container:hover {
    background-color: #0F488C;
    border-radius: 5px;
}

.sidebar-wrapper-v2:hover h3 {
    color: #ffffff;
    display: inline-block;
}

.sidebar-wrapper-v2:hover {
    width: auto;
    z-index: 99;
}

.main-menu {
    border-right: 1px solid #e5e5e5;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    width: 60px;
    overflow: hidden;
    -webkit-transition: width .05s ease-in;
    transition: width .05s ease-in;
    -webkit-transform: translateZ(0) scale(1, 1);
    // z-index: 1000;
}

.sub_menu_enable:hover .sidebar-wrapper-v2 {
    width: 500px;
}

.nav_sub_menu {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    height: 100vh;
    gap: 10px;
    padding: 10px;
    overflow: scroll;
    // width: 260px;
    min-width: 270px;
    max-width: 100%;
}

.nav_sub_menu h3 {
    color: #333860 !important;
    cursor: pointer;
    padding: 5px;
}

.menus_container {
    display: flex;
    flex-direction: row;
}

.sidebar-wrapper-v2:hover .sidebar_hr {
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
}

.sidebar_hr {
    border-bottom: 1px solid #576B87 !important;
    width: 60%;
    margin-right: 31px;
    margin-bottom: 0px;
}

.sidebar_bottom_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: calc(100vh - 520px);
    justify-content: end;
    height: 100%;
    padding: 10px;
}

.--hr_mr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.sidebar-wrapper-v2:hover .--hr_mr {
    margin-bottom: 10px;
}

.cusor_disable h3 {
    display: none !important;

}

.cusor_disable {
    width: 60px !important;
}


.cusor_disable .nav_sub_menu {
    // cursor: none !important;
    display: none;
}

.avather_text_eraper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.avather_text_eraper h3 {
    white-space: nowrap;
    width: 160px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav_sub_menu h3:hover {
    font-weight: 500;
    border-radius: 5px;
    // box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 2px 3px 6px 1px rgba(0, 0, 0, 0.19);
    padding: 5px 5px;
    transition-timing-function: ease-in-out;
    color: #516beb !important
}

.expanded .sub-menu-indicator {
    display: none;
}

.expanded .sidebar_hr {
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
}

.expanded .--hr_mr {
    margin-bottom: 10px;
}

.sub-menu-indicator_chevron_right {
    display: none !important;
}

.sidebar-wrapper-v2:hover .sub-menu-indicator_chevron_right {
    display: inline-block !important;
    position: absolute;
    right: 0;
    margin-top: 5px;
}

.expanded .sub-menu-indicator_chevron_right {
    display: inline-block !important;
    position: absolute;
    right: 0;
    margin-top: 5px;
}

.is_client_log {
    background-color: #001964 !important;
}

.sidebar-wrapper-v2 {
    pointer-events: none;
}

.sidebar-wrapper-v2 .siebar_top_section_container {
    pointer-events: auto;
}

.sidebar-wrapper-v2 .siebar_top_section_container:hover {
    pointer-events: auto;
}

.sidebar-wrapper-v2 .sidebar_icon_text_container {
    pointer-events: auto;
}

.sidebar-wrapper-v2 .nav_sub_menu {
    pointer-events: auto;
}

.sidebar-wrapper-v2 .nav_sub_menu:hover {
    pointer-events: auto;
}

.sidebar-wrapper-v2 .sidebar-top-v2:hover {
    pointer-events: auto;
}

.siebar_top_section_container:hover .active_menu {
    width: 100%;
}

.sidebar-wrapper-v2:hover .active_menu {
    width: 100%;
}



// .sidebar-wrapper-v2 .sidebar_icon_text_container:hover {
//     width: auto !important;
//     z-index: 99 !important;
// }