.typo-itemization {
  color: #adadad;
  font-weight: 400;
}

.itemization-form-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.itemization-form-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemization-footer-section {
  display: flex;
  align-items: center;
  justify-content: end;
}

.itemization-custom-field-container {
  background-color: #f8f8fb;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.itemization-date-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.commission-type-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.without-with-commission-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.itemization-form-width {
  width: 220px;
}

.comission-typo {
  text-align: left;
  margin-bottom: 5px;
}

.itemization-custom-field {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.itemization-check-box {
  font-weight: 400;
  margin-top: 4px;
}

.itemiza-amount-typo {
  font-weight: 400;
}
