.card-parent:hover .card-child {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


.thank__res {
  background: #ffffff;
  padding: 30px 25px;
  box-shadow: #00000030 0px 8px 16px -8px;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 50%;
}

@media (min-width: 300px) and (max-width: 800px) {
  .thank__res {
    width: 100%;
    height: 70vh;
  }
}