.mx-h1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  color: $text;

  &--medium {
    font-weight: 500;
  }
}

.mx-h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: $text;

  &--medium {
    font-weight: 500;
  }
}

.mx-h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: $text;

  &--medium {
    font-weight: 500;
  }

  &--regular {
    font-weight: 400;
  }
}

.mx-h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: $text;

  &--regular {
    font-weight: 400;
  }
}

.mx-p {
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  color: $text;

  &--regular {
    font-weight: 400;
  }

  &--bold {
    font-weight: 600;
  }

  &--small {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
  }
}

.mx-a {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;

  &--medium {
    font-size: 12px;
    line-height: 14.4px;
  }

  &--small {
    font-size: 11px;
    line-height: 12.2px;
  }
}

.mx-label {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  color: $text;

  &--small {
    font-size: 10px;
    line-height: 12px;
  }
}
