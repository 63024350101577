.wf-discription__action-made {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 3px;
}
.wf-discription__action-icon {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  margin-bottom: 5px;
  align-items: center;
}
.wf-action__ali {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
}
.ctp-discription--clamp {
  padding-top: 5px;
}
.ctp-discription--clamp p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex: 1;
}
.wf-add-form__select-con {
  display: flex;
  flex-direction: row;
}
.wf-add-form__select-max-contact {
  width: 100%;
  margin-right: 10px;
}
.wf-add-form__select-avg-delay {
  width: 100%;
}
.wf-add-form__btn-con {
  display: flex;
  justify-content: space-between;
}
.wf-select option {
  color: #adadad;
}
select {
  //   /* Reset Select */
  //   appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  //   /* Personalize */
  //   flex: 1;
  padding-right: 10px;
  color: #adadad;
  background-color: var(--darkgray);
  //   background-image: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}

// /* Remove IE arrow */
// select::-ms-expand {
//   display: none;
// }
/* Custom Select wrapper */
// .select-1 {
//   position: relative;
//   display: flex;
//   height: 3em;
//   border-radius: 0.25em;
//   overflow: hidden;
//   width: 100px;
// }
// /* Arrow */
// .select-1::after {
//   content: "▼";
//   color: #adadad;
//   position: absolute;
//   top: 0;
//   right: 0;
//   padding: 1em 0em 1em 0em;
//   pointer-events: none;
//   left: 80px;
// }
// .select-down-arrow::after {
//   content: "\1F869";
//   color: #adadad;
//   position: absolute;
//   top: 99px;
//   right: 0;
//   padding: 1em 0em 1em 0em;
//   pointer-events: none;
//   left: 25%;
// }

// .select-3 {
//   position: relative;
//   display: flex;
//   height: 3em;
//   border-radius: 0.25em;
//   overflow: hidden;
//   align-items: center;
// }
// /* Arrow */
// .select-3::after {
//   color: #adadad;
//   position: absolute;
//   top: 0;
//   right: 0;
//   padding: 1em 0em 1em 0em;
//   pointer-events: none;
//   left: 80px;
// }

.tem-filter-select-icon {
  margin-left: 10px;
}
.tem-filter-select-icon svg path {
  fill: #adadad;
}
.fielvisitaddress {
  min-width: 120px;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  // width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000 !important;
}

.fielvisitaddress_cancel_width {
  min-width: 120px;
  max-width: calc(100% - 90px);
  white-space: nowrap;
  // width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000 !important;
}