.prf_table-main__body-row{
 border-bottom: 1px solid #cdced9;
 padding: 0px 0px 0px 3px;
 border-right: 1px solid #cdced9;
 border-left: 1px solid #cdced9;
 background-color: #ffffff;
}

.prf_table-main__body-row .table-main__body-cell{
    border-right: 1px solid #cdced9; 
}

.prf_table-main__head{
  display: table-caption;
  position: sticky;
  top: 0;
}

$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;

$white: #ffffff;

.pfr_db_table_access {
//   max-width: 500px;
//   margin: auto;
//   margin-top: 3rem;
width: 100%;
  overflow-x: auto;
}

.pfr_db_table_access table {
    // font-family: "Open Sans", sans-serif;
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
    // border: none;
    border-radius: 0.5rem;
    border: 1px solid #cdced9;
    // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    // * {
    //     border: none;
    // }
    // white-space: nowrap;
    thead tr {
        color: $gray-800;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;

        th {
            background: $gray-200;
            padding: 0.75rem 1.5rem;
            vertical-align: middle;
        }
    }

    tbody {
        tr td {
            background: $white;
        }

        // tr:nth-child(even) td {
        //     background: $gray-200;
        // }
        // td {
        //     color: $gray-900;
        //     text-align: left;
        //     // padding: 1.5rem 1.5rem;
        //     vertical-align: middle;
        //     font-size: 1.125rem;
        //     font-weight: normal;
        // }
    }

    // tr:last-child td:first-child {
    //     border-bottom-left-radius: 0.5rem;
    // }

    // th:first-child {
    //     border-top-left-radius: 0.5rem;
    // }

    // tr:last-child td:last-child {
    //     border-bottom-right-radius: 0.5rem;
    // }

    // th:last-child {
    //     border-top-right-radius: 0.5rem;
    // }
  
  tr>th:first-child,tr>td:first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
  }
  tr>th {
    position: sticky !important;
  }
}

.pfr_filter_container {
    display: flex;
    justify-content: start;
    gap: 5px;
    flex-wrap: wrap;
  }

  .pfr_chart_container{
    width: 350px;
    padding-left: 10px;
    background-color: #ffffff;
    border: 1px solid #cdced9;
    border-radius: 5px;
    margin-left: 10px;
    padding-top: 10px;
  }

  .pfr_db_table_chart_container{
    display: flex;
    height: calc(100vh - 90px);
  }

  .pfr_title_style{
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cdced9;
  }

  .revenue_bill_href p {
    color: #516beb;
  }

  .pre_dash_date_picker input{
    border: 1px solid #cdced9;
    border-radius: 5px;
    padding: 5px;
  }